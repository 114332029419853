import { AccountType } from '@planyway-common/common';
export interface IPlanywayMember {
  memberId: string;
  memberName: string;
  memberEmail: string;
  memberInitials: string;
  memberAvatarUrl: string;
  memberUserName?: string;
  canAdd?: boolean;
  firstVisit?: string;
}

export interface ITrelloMember {
  id: string;
  idMember: string;
  avatarUrl: string;
  email: string;
  fullName: string;
  initials: string;
  username: string;
  firstVisit?: string;
  idOrganizations: string[];
  idBoards: string[];
}

export interface ITrelloOrganization {
  displayName?: string;
  id: string;
  logoHash?: string | null;
  name?: string;
  memberships?: ITrelloMember[];
}

export interface ITrelloBoard {
  id: string;
  name?: string;
  url?: string;
  memberships?: ITrelloMember[];
}

export interface ITrelloBoardAndOrganization
  extends ITrelloBoard,
    ITrelloOrganization {}

export class User {
  id: string;
  name: string;
  email: string;
  initials: string;
  avatarUrl: string;
  userName?: string;
  canAdd?: boolean;
  canRemove?: boolean;
  errMessage?: string;
  warnMessage?: string;
  isCurrent: boolean;
  firstVisit?: string;
  commonBoardCount: number;
  organizationsIds?: string[];
  boardIds?: string[];

  constructor(member: ITrelloMember, isCurrent = false) {
    this.id = member.id;
    this.name = member.fullName;
    this.email = member.email;
    this.initials = member.initials;
    this.avatarUrl = member.avatarUrl;
    this.userName = member.username;
    this.firstVisit = member.firstVisit;
    this.organizationsIds = member.idOrganizations;
    this.boardIds = member.idBoards;

    this.canAdd = true;
    this.canRemove = true;
    this.isCurrent = isCurrent;
  }

  get avatar(): string {
    if (this.avatarUrl) {
      return `${this.avatarUrl}/original.png`;
    }
  }
}

export class PlanywayUser {
  accountType: AccountType;
  id: string;
  name?: string;
  email?: string;
  avatarUrl?: string;
  trelloMemberId?: string;
  googleUserId?: string;
  outlookUserId?: string;
  isCurrent: boolean;
  errMessage?: string;
  isPlanywayUser?: boolean;
  isNotExisted?: boolean;

  constructor(
    data: PlanywayUserDTO,
    isCurrent = false,
    isPlanywayUser = false,
  ) {
    this.accountType = data.accountType;
    this.id = data.userId;
    this.email = data.userEmail;
    this.name = data.userName;
    this.avatarUrl = data.userAvatar;
    this.trelloMemberId = data.trelloMemberId;
    this.isNotExisted = data.isNotExisted;
    this.isCurrent = isCurrent;
    this.isPlanywayUser = isPlanywayUser;
  }

  get avatar(): string {
    return this.avatarUrl
  }

  static getUserDTO(user: PlanywayUser): PlanywayUserDTO {
    return {
      accountType: user.accountType,
      userId: user.id,
      userEmail: user.email,
      userName: user.name,
      userAvatar: user.avatarUrl,
      trelloMemberId: user.trelloMemberId,
      trelloMemberName: user.name,
      isNotExisted: user.isNotExisted
    }
  }
}

export interface PlanywayUserDTO {
  accountType?: AccountType,
  userId: string;
  userEmail?: string;
  userName?: string;
  userAvatar?: string;
  trelloMemberId?: string;
  trelloMemberName?: string;
  isNotExisted?: boolean;
}

export interface UserUpdatesDTO {
  userEmail?: string;
  userName?: string;
  userAvatar?: string|null;
}
