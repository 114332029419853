import { v1 as uuid } from 'uuid';
import {
  HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpSentEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TelemetryService } from '@app/core/services';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpDurationLogInterceptor implements HttpInterceptor {
  private _requests = new Map<string, Request>();

  constructor(private _telemetrySrv: TelemetryService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestId = uuid();

    this._requests.set(requestId, {
      requestId,
      url: req.url,
      method: req.method,
      startTime: Date.now()
    });

    return next.handle(req)
      .pipe(
        tap((response: HttpSentEvent | HttpResponse<any>) => {
          if (response.type === HttpEventType.Sent || !this._requests.has(requestId)) {
            return;
          }

          const endTime = Date.now();
          const request = this._requests.get(requestId);
          this._requests.delete(requestId);

          let duration = endTime - request.startTime;
          if (duration < 0 || duration > 300000) {
            // Некорректная длительность запроса
            duration = 0;
          }

          this._logRequest(request, duration, response.status);
        }),
        catchError(err => {
          if (!this._requests.has(requestId)) {
            return;
          }

          const endTime = Date.now();
          const request = this._requests.get(requestId);
          this._requests.delete(requestId);

          let duration = endTime - request.startTime;
          if (duration < 0 || duration > 300000) {
            // Некорректная длительность запроса
            duration = 0;
          }

          this._logRequest(request, duration, err.status, false);

          return throwError(err);
        })
      );
  }

  private _logRequest(request: Request, duration: number, status: number, isFailed = true) {
    this._telemetrySrv.trackDependency(request.requestId, request.method, request.url, duration, isFailed, status);
  }
}

type Request = {
  requestId: string,
  url: string,
  method: string,
  startTime: number
}
