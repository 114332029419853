import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@app/core/services/window/window.service';
import { BASE_TITLE } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root',
})
export class SeoService implements OnDestroy {
  utmTags: any = {};

  _isBrowser: boolean;

  constructor(
    @Inject(WINDOW) private _window: Window | any,
    @Inject(DOCUMENT) private _document: Document,
    private _title: Title,
    private _meta: Meta,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId,
  ) {

    this._isBrowser = isPlatformBrowser(platformId);
  }

  private get _ga() {
    return this._window.ga;
  }

  init() {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        this._ga('set', 'page', event.urlAfterRedirects);
        this._ga('send', 'pageview');

        let url = this._window.location.href;
        if (!this._isBrowser) {
          url = this._window.location.origin + event.urlAfterRedirects;
        }

        this._meta.updateTag({
          property: 'og:url',
          content: url.split('?')[0],
        });

        this.updateCanonicalUrl(this._window.location.origin + this._window.location.pathname);
      }),
      map(() => this._activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
    ).subscribe(data => {
      const routeTitle = data['title'];
      const withoutBaseTitle = data['withoutBaseTitle'];

      if (routeTitle) {
        this.setTitle(routeTitle, withoutBaseTitle ? '' : BASE_TITLE);
      }

      const tags = data['tags'];
      if (tags) {
        this.updateTags(tags);
      }
    });

    this._activatedRoute.queryParams.subscribe(params => {
      if (params['utm_source'] && !this.utmTags.utm_source) {
        this.utmTags.utm_source = params['utm_source'];
      }

      if (params['utm_medium'] && !this.utmTags.utm_medium) {
        this.utmTags.utm_medium = params['utm_medium'];
      }

      if (params['utm_campaign'] && !this.utmTags.utm_campaign) {
        this.utmTags.utm_campaign = params['utm_campaign'];
      }

      if (params['fbclid'] && !this.utmTags.fbclid) {
        this.utmTags.fbclid = params['fbclid'];
      }
    });
  }

  setTitle(title: string, base?: string) {
    if (base) {
      this._title.setTitle(`${base} - ${title}`);
    } else {
      this._title.setTitle(title);
    }
  }

  updateTags(tags: any[]) {
    for (const tag of tags) {
      this._meta.updateTag(tag);
    }
  }

  updateCanonicalUrl(url: string) {
    const head = this._document.head;
    let element: HTMLLinkElement = this._document.querySelector(`link[rel='canonical']`);

    if (!element) {
      element = this._document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }

    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  ngOnDestroy(): void {}
}
