import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { HttpService } from '@app/core/services/http/http.service';
import { LogService } from '@app/core/services/log/log.service';
import { BACKEND_URL } from '@app/shared/constants';
import { BackendResponseBody } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {

  constructor (
    private _http: HttpService,
    private _logSrv: LogService,
  ) {}

  sendFeedback(data: IFeedbackData, options?) {
    let feedbackData = {
      ...data,
      isEnterpriseContactUs: Boolean(options?.isEnterprise),
    }

    return this._getFeedbackLink().pipe(
      filter(feedbackLink => !!feedbackLink),
      switchMap(feedbackLink => this._sendFeedback(feedbackLink, feedbackData)),
      catchError(err => {
        this._logSrv.error('Failed to send:');
        this._logSrv.error(err);

        return of(false);
      }),
    )
  }

  _getFeedbackLink(): Observable<string> {
    return this._http
      .get<BackendResponseBody<string>>(`${BACKEND_URL}/feedback/link`)
      .pipe(map(response => response.data));
  }

  _sendFeedback(uploadLink: string, data: IFeedbackData): Observable<any> {
    return this._http.put(uploadLink, {
      headers: {
        'x-ms-blob-type': 'BlockBlob',
      },
      body: data,
    });
  }
}

export interface IFeedbackData {
  userId: string;
  userName: string;
  userEmail: string;
  message: string;
  browserName: string;
  browserVersion: string;
  browserLocale: string,
  timeZone: string,
  appType: string;
  attachments?: { name: string; content: string }[];
}
