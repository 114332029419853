import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/services/auth/auth.service';
import { BackendResponse, PlanywayUser, PlanywayUserDTO, RequestMethods, } from '@app/shared/models';
import { BACKEND_URL } from '@app/shared/constants';
import { ReplaySubject } from 'rxjs';
import { filter, map, shareReplay, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private planywayUserSubject = new ReplaySubject<PlanywayUser>();
  readonly planywayUser$ = this.planywayUserSubject
    .asObservable()
    .pipe(shareReplay(1));

  constructor(private _authSrv: AuthService) {
    this._authSrv.planywayUserId$.subscribe(userId => this._loadUser(userId));
  }

  private _loadUser(userId: string): void {
    if (!userId) {
      this.planywayUserSubject.next(null);
      return;
    }

    this._authSrv
      .authorizedRequest<BackendResponse<PlanywayUserDTO>>(
        RequestMethods.Get,
        `${BACKEND_URL}/planyway/${userId}/user`,
        {},
        { usePlanywayAuth: true },
      )
      .pipe(take(1))
      .subscribe(({ body: { data } }) =>
        this.planywayUserSubject.next(new PlanywayUser(data, true)),
      );
  }

  async getUsers(userIds: string | string[]) {
    return await this._authSrv.planywayUserId$
      .pipe(
        filter(userId => !!userId),
        take(1),
        switchMap(userId =>
          this._authSrv.authorizedRequest<BackendResponse<PlanywayUserDTO[]>>(
            RequestMethods.Post,
            `${BACKEND_URL}/planyway/${userId}/user`,
            {
              body: {
                action: 'getUsers',
                data: {
                  userIds,
                },
              },
            },
            { usePlanywayAuth: true },
          ),
        ),
        map(({ body: { data } }) => data),
      )
      .toPromise();
  }

  reloadUser() {
    this._authSrv.planywayUserId$.pipe(take(1)).subscribe(userId => this._loadUser(userId));
  }

  lookupUser(data) {
    return this._authSrv.planywayUserId$.pipe(
      filter(userId => !!userId),
      take(1),
      switchMap(userId =>
        this._authSrv.authorizedRequest<BackendResponse<PlanywayUserDTO[]>>(
          RequestMethods.Post,
          `${BACKEND_URL}/planyway/${userId}/user`,
          {
            body: {
              action: 'lookupUser',
              data,
            },
          },
          { usePlanywayAuth: true },
        ),
      ),
    );
  }

  createUser(data) {
    return this._authSrv.planywayUserId$.pipe(
      filter(userId => !!userId),
      take(1),
      switchMap(userId =>
        this._authSrv.authorizedRequest<BackendResponse<PlanywayUserDTO[]>>(
          RequestMethods.Post,
          `${BACKEND_URL}/planyway/${userId}/user`,
          {
            body: {
              action: 'createUser',
              data,
            },
          },
          { usePlanywayAuth: true },
        ),
      ),
    );
  }
}
