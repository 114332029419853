import { Injectable } from '@angular/core';
import { ReplaySubject, of } from 'rxjs';
import { finalize, shareReplay, take } from 'rxjs/operators';

import { AuthService } from '@app/core/services/auth/auth.service';
import { BACKEND_URL } from '@app/shared/constants';
import { delayPromise } from '@app/shared/utils';
import {
  BackendResponse, IPaymentData, LicenseType, PaymentPlatform, PlanywaySubscription, RawSubscription, RequestMethods,
} from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private planywaySubscriptionSubject = new ReplaySubject<PlanywaySubscription>();
  readonly planywaySubscription$ = this.planywaySubscriptionSubject
    .asObservable()
    .pipe(shareReplay(1));

  private planywayUserId: string;
  private paymentPlatform: PaymentPlatform;

  isSubscriptionLoading = false;

  constructor(
    private _authSrv: AuthService,
  ) {
    this._authSrv.planywayUserId$.subscribe(
      userId => (this.planywayUserId = userId),
    );
  }

  loadUserSubscription() {
    this.isSubscriptionLoading = true;

    this._authSrv
      .authorizedRequest<BackendResponse<PlanywaySubscription>>(
        RequestMethods.Get,
        `${BACKEND_URL}/planyway/${this.planywayUserId}/subscription`,
        {},
        { usePlanywayAuth: true },
      )
      .pipe(
        take(1),
        finalize(() => (this.isSubscriptionLoading = false)),
      )
      .subscribe(({ body: { data } }) => {
        this.paymentPlatform = data['paymentPlatform'];
        this.planywaySubscriptionSubject.next(new PlanywaySubscription(data));
      });
  }

  loadSubscriptionMembers() {}

  checkout() {}

  changeUsers(userIds: string[]) {
    return this._authSrv.authorizedRequest<BackendResponse<any>>(
      RequestMethods.Post,
      `${BACKEND_URL}/planyway/${this.planywayUserId}/subscription`,
      {
        body: {
          action: 'changeUsers' + this.paymentPlatform,
          data: {
            userIds,
          },
        },
      },
      { usePlanywayAuth: true },
    );
  }

  changePlan(data: IPaymentData) {
    return this._authSrv.authorizedRequest<BackendResponse<any>>(
      RequestMethods.Post,
      `${BACKEND_URL}/planyway/${this.planywayUserId}/subscription`,
      {
        body: {
          action: 'changePlan' + this.paymentPlatform,
          data,
        },
      },
      { usePlanywayAuth: true },
    );
  }

  /**
   * Меняем админа подписки
   *
   * @param {string} newOwnerId идентификатор пользователя, которому передаем права
   *
   * @returns {Promise<void>}
   */
  transferOwnership(newOwnerId: string) {
    return this._authSrv.authorizedRequest<BackendResponse<any>>(
      RequestMethods.Post,
      `${BACKEND_URL}/planyway/${this.planywayUserId}/subscription`,
      {
        body: {
          action: 'changeAdmin' + this.paymentPlatform,
          data: {
            newPaymentAdminId: newOwnerId
          },
        },
      },
      { usePlanywayAuth: true },
    );
  }

  reloadSubscription() {
    this.planywaySubscriptionSubject.pipe(take(1)).subscribe(() => this.loadUserSubscription())
  }

  cancel() {
    return this._authSrv.authorizedRequest<BackendResponse<any>>(
      RequestMethods.Post,
      `${BACKEND_URL}/planyway/${this.planywayUserId}/subscription`,
      {
        body: {
          action: 'cancel' + this.paymentPlatform,
        },
      },
      { usePlanywayAuth: true },
    );
  }

  signOut() {
    this.planywaySubscriptionSubject.next(null);
    return of(true);
  }

  /**
   * Перезагружает подписку пользователя
   *
   * @param {LicenseType} licenseType тип подписки
   * @param {number} retryCount количество повторов
   *
   * @returns {Promise<boolean>} True, если загрузилась новая подписка, иначе - false
   */
  async pollMemberSubscriptionAsync(
    licenseType: LicenseType,
    retryCount = 2,
  ): Promise<boolean> {
    const {
      body: { success, data: newSubscription },
    } = await this._authSrv
      .authorizedRequest<BackendResponse<RawSubscription>>(
        RequestMethods.Get,
        `${BACKEND_URL}/planyway/${this.planywayUserId}/subscription`,
        {},
        { usePlanywayAuth: true },
      )
      .toPromise();

    if (success && newSubscription) {
      if (
        licenseType === newSubscription.licenseType &&
        new Date(newSubscription.expirationDate) > new Date()
      ) {
        this.planywaySubscriptionSubject.next(
          new PlanywaySubscription(newSubscription),
        );
        return true;
      }
    }

    if (retryCount > 0) {
      const timeout = 5 * (3 - retryCount) * 1000;
      await delayPromise(timeout);

      return await this.pollMemberSubscriptionAsync(
        licenseType,
        retryCount - 1,
      );
    }

    return false;
  }
}
