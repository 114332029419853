import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { Observable, Observer, of } from 'rxjs';
import { share } from 'rxjs/operators';
import { LogService } from '@app/core/services/log/log.service';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {

  private readonly _appendedScripts: Set<string> = new Set();
  private readonly _loadedScripts: Set<string> = new Set();
  private readonly _loadingScriptsObservers: Map<string, Observable<boolean>> = new Map<string, Observable<boolean>>();

  constructor(
    @Inject(DOCUMENT) private _document,
    @Inject(PLATFORM_ID) private _platformId,
    private _logger: LogService,
  ) {
  }

  load(src: string, id?: string, type?: string): Observable<boolean> {
    if (!isPlatformBrowser(this._platformId)) {
      // Prevent loading scripts on server side
      return of(false);
    }

    if (this._loadingScriptsObservers.has(src)) {
      return this._loadingScriptsObservers.get(src);
    }

    const loadObservable = new Observable<boolean>((observer: Observer<boolean>) => {
      if (this._loadedScripts.has(src)) {
        observer.next(true);
        observer.complete();

        this._loadingScriptsObservers.delete(src);
        return;
      }

      const scriptElement = this._document.createElement('script');
      scriptElement.type = type || 'text/javascript';
      scriptElement.src = src;
      scriptElement.id = id || '';

      scriptElement.onload = () => {
        this._loadedScripts.add(src);
        observer.next(true);
        observer.complete();

        this._loadingScriptsObservers.delete(src);
      };

      scriptElement.onerror = (error: any) => {
        this._logger.error(`Loading src ${src} error: `, error);
        this._loadingScriptsObservers.delete(src);
        observer.next(false);
        observer.complete();
      };

      this._document.body.appendChild(scriptElement);
    }).pipe(
      share(),
    );

    this._loadingScriptsObservers.set(src, loadObservable);
    return loadObservable;
  }

  appendRawScript(src: string, id: string, type?: string, extraAtts?: {key: string; value: string;}[]) {
    if (this._appendedScripts.has(id)) {
      return;
    }

    const scriptElement = this._document.createElement('script');
    scriptElement.type = type || 'text/javascript';
    scriptElement.id = id;
    scriptElement.innerHTML = src.replace(/\r?\n|\r/g, '');

    if(extraAtts) {
      extraAtts.forEach(({key, value}, idx) => {
        scriptElement.setAttribute(key, value);
      });
    }

    this._appendedScripts.add(id);

    this._document.body.appendChild(scriptElement);
  }
}
