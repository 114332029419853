import { NgModule } from '@angular/core';
import { AppComponent } from '@app/app.component';
import { AppModule } from './app.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpDurationLogInterceptor } from '@app/shared/interceptors/telemetry.interceptor';

@NgModule({
  imports: [
    AppModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpDurationLogInterceptor,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppBrowserModule {
}
