import { Injectable } from '@angular/core';
import { Platform } from '@app/core/services/platform/platform.model';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  private _currentPlatform = Platform.Trello;

  private _platformUpdatedSubject = new Subject<void>();
  platformUpdated$ = this._platformUpdatedSubject.asObservable();

  constructor() {}

  get currentPlatform() {
    return this._currentPlatform;
  }

  getPlatformSpecificUrl(baseUrl = '', platform = this.currentPlatform): string {
    switch (platform) {
      case Platform.Jira:
        return `/jira${baseUrl}`;

      default:
        return baseUrl;
    }
  }

  setCurrentPlatform(platform = Platform.Trello) {
    if (!Object.values(Platform).includes(platform)) {
      this._currentPlatform = Platform.Trello;

      return;
    }

    this._currentPlatform = platform;
    this._platformUpdatedSubject.next();
  }
}
