export enum CurrencyMark {
  USD = '$',
  ARS = 'ARS',
  AUD = 'A$',
  BRL = 'R$',
  CAD = 'CA$',
  CNY = '¥',
  CZK = 'Kč',
  DKK = 'Kr.',
  EUR = '€',
  HKD = 'HK$',
  HUF = 'Ft',
  INR = '₹',
  JPY = '¥',
  MXN = 'Mex$',
  TWD = 'NT$',
  NZD = 'NZ$',
  NOK = 'kr',
  PLN = 'zł',
  GBP = '£',
  RUB = '₽',
  SGD = 'S$',
  ZAR = 'R',
  KRW = '₩',
  SEK = 'kr',
  CHF = 'Fr.',
  THB = '฿',
}

export enum CurrencyWithoutFraction {
  'JPY',
  'KRW',
}

export function isCurrencyWithoutFraction(currency: string) {
  return (<any>Object).values(CurrencyWithoutFraction).includes(currency);
}
