export enum AppType {
  Chrome = 'chromeExtension',
  Safari = 'safariExtension',
  PowerUp = 'powerup',
  Teams = 'teams',
  Jira = 'jira',
  iOS = 'iOSApp',
  Android = 'androidApp',
  Site = 'site',
  Standalone = 'standalone',
  Trello = 'trello',
  GitHub = 'github'
}

export enum SignInRegions {
  Article = 'Blog Article',
  Menu = 'Menu',
  Header = 'Header',
  Footer = 'Footer',
  Features = 'Features',
  Sidebar = 'Sidebar',
  Platforms = 'Platform Block',
  Reviews = 'Reviews',
}

export enum DeviceType {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
}

export enum CtaName {
  ContinueWithTrello = 'Continue with Trello',
  ContinueWithJira = 'Continue with Jira',
  GetStartedFree = 'Get started free',
  GoToApp = 'Go to App',
  SignInWithTrello = 'Sign in with Trello',
  TryForFree = 'Try for free',
  InstallForJira = 'Install for Jira',
  GetStartedWithTrello = 'Get started with Trello',
  GetStartedWithJira = 'Get started with Jira',
  StartWithJira = 'Start with Jira',
  ReadMoreRevies = 'Read more reviews',
  WatchVideo = 'Watch 1m video',
  TryItNow = 'Try it now',
  AddToChrome = 'Add to Chrome',
  GetPlanywayExtension = 'Get Planyway Extension',
  GetStartedWithGitHub = 'Get started with GitHub',
}

export enum RequestMethods {
  Get,
  Post,
  Put,
  Delete,
}
