import { LastPayment, NextPayment, PaymentPeriod } from '@app/shared/models/pricing/billing';
import { getIntervalDays } from '../utils';
import { PaymentPlatform } from './pricing/payments';
import { PlanywayUser, User } from './user';

export interface RawSubscription {
  licenseType: LicenseType;
  isLicenseActive: boolean;
  expirationDate: Date;
  isTrialAvailable: boolean;
  isSubscription: boolean;
  isSubscriptionAdmin?: boolean;
  subscriptionAdminId?: string;
  subscriptionStatus?: SubscriptionStatus|string;
  paymentPeriod?: PaymentPeriod;
  paymentPlatform?: PaymentPlatform;
  quantity?: number;
  priceModel?: PriceModel;
  firstPaymentDate?: Date;
  disableUpdates?: boolean;
  disableControl?: boolean;
  addedUserIds?: string[];
  lastPayments?: LastPayment[];
  nextPayment?: NextPayment;
  changePaymentMethodUrl?: string;
}

export interface Payment {
  amount: number;
  currency: string;
  date: Date;
  receiptUrl?: string;
}

export interface IShortLicense {
  memberId: string;
  licenseType: LicenseType;
  isLicenseActive: boolean;
}

export enum LicenseType {
  Free = 'FREE',
  Trial = 'TRIAL',
  Pro = 'PRO',
  Team = 'TEAM',
  Business = 'BUSINESS',
  Enterprise = 'ENTERPRISE',
  Individual = 'INDIVIDUAL',
}

export const UnavailableLicense = [
  LicenseType.Pro,
];

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  PastDue = 'PAST_DUE',
  Cancelled = 'CANCELLED',
}

const SubscriptionStatusMap = {
  [SubscriptionStatus.Active]: 'Active',
  [SubscriptionStatus.PastDue]: 'Past due',
  [SubscriptionStatus.Cancelled]: 'Cancelled',
};

const PaymentPeriodsMap = {
  'MONTH': 'monthly',
  'YEAR': 'annual',
};

enum PriceModel {
  Team_$15x10 = 'TEAM_$15x10',
  Team_$10x5 = 'TEAM_$10x5',
}

export class PlanywaySubscription {
  readonly licenseType: LicenseType;
  readonly isLicenseActive: boolean;
  readonly expirationDate: Date;
  readonly isTrialAvailable: boolean;
  readonly isSubscription: boolean;
  readonly isSubscriptionAdmin: boolean;
  readonly paymentPeriod: PaymentPeriod;
  readonly paymentPlatform: PaymentPlatform;
  readonly subscriptionStatus: string;
  readonly quantity: number;
  readonly firstPaymentDate: Date;

  readonly priceModel: PriceModel;

  readonly disableControl: boolean;
  readonly disableUpdates: boolean;
  readonly ignoreCancelledExpiration: boolean;

  readonly lastPayments: LastPayment[];
  readonly nextPayment: NextPayment;
  readonly changePaymentMethodUrl: string;
  readonly subscriptionAdminId: string;
  readonly addedUserIds: string[];

  licenseAdmin: PlanywayUser;
  subscriptionMembers: User[];
  addedUsers = [];

  private readonly _trialDays: number | null;

  constructor(subscriptionData: RawSubscription) {
    this.licenseType = subscriptionData.licenseType;
    this.isLicenseActive = subscriptionData.isLicenseActive;
    this.isSubscription = subscriptionData.isSubscription;
    this.isTrialAvailable = subscriptionData.isTrialAvailable;
    this.addedUserIds = subscriptionData.addedUserIds || [];

    if (this.licenseType === LicenseType.Free) {
      return;
    }

    if (subscriptionData.lastPayments) {
      this.lastPayments = subscriptionData.lastPayments.map(payment => {
        return {
          currency: payment.currency,
          amount: payment.amount,
          date: new Date(payment.date),
          receiptUrl: payment.receiptUrl,
        };
      });
    }

    this.expirationDate = subscriptionData.expirationDate && new Date(subscriptionData.expirationDate) || null;
    // Считаем истёкшую лицензию как free
    if (this.isExpired) {
      this.licenseType = LicenseType.Free;
      return;
    }

    this.paymentPeriod = subscriptionData.paymentPeriod;
    this.paymentPlatform = subscriptionData.paymentPlatform;
    this.subscriptionStatus = subscriptionData.subscriptionStatus;
    this.quantity = subscriptionData.quantity;
    this.priceModel = subscriptionData.priceModel;
    this.firstPaymentDate = subscriptionData.firstPaymentDate && new Date(subscriptionData.firstPaymentDate);
    this.disableControl = subscriptionData.disableControl;
    this.disableUpdates = subscriptionData.disableUpdates;
    this.changePaymentMethodUrl = subscriptionData.changePaymentMethodUrl;
    this.isSubscriptionAdmin = subscriptionData.isSubscriptionAdmin;
    this.subscriptionAdminId = subscriptionData.subscriptionAdminId;

    if (subscriptionData.nextPayment) {
      this.nextPayment = {
        amount: subscriptionData.nextPayment.amount,
        currency: subscriptionData.nextPayment.currency,
        date: new Date(subscriptionData.nextPayment.date),
      };
    }

    this.licenseType = subscriptionData.licenseType;
    this.expirationDate = subscriptionData.expirationDate && new Date(subscriptionData.expirationDate) || null;

    this._trialDays = this.licenseType == LicenseType.Trial && getIntervalDays(new Date(), this.expirationDate);
  }

  get title(): string {
    return getLicenseTitle(this.licenseType);
  }

  isChangePackAvailable() {
    if (!this.canUpdateLicense || (this.isCancelled && !this.isExpired)) {
      return false;
    }

    return true;
  }

  get isLegacyPlan() {
    switch (this.priceModel) {
      case PriceModel.Team_$10x5:
      case PriceModel.Team_$15x10:
        return true;

      default:
        break;
    }
  }

  get canUpdateQuantity() {
    return this.isLegacyPlan || this.licenseType === LicenseType.Team;
  }

  get canUpdateLicense() {
    if (this.isExpired && !this.ignoreCancelledExpiration) {
      return false;
    }

    if (this.subscriptionAdminId) {
      return false;
    }

    return !this.disableUpdates;
  }

  get canUpdateMembers() {
    if (this.isLegacyPlan) {
      return true;
    }

    return this.licenseType === LicenseType.Team || this.licenseType === LicenseType.Pro;
  }

  get paymentPeriodText() {
    return PaymentPeriodsMap[this.paymentPeriod] || this.paymentPeriod;
  }

  get subscriptionStatusText() {
    return SubscriptionStatusMap[this.subscriptionStatus] || this.subscriptionStatus;
  }

  get trialDays() {
    return this._trialDays;
  }

  get isExpired(): boolean {
    return this.expirationDate && this.expirationDate < new Date();
  }

  get isCancelled(): boolean {
    return this.subscriptionStatus === SubscriptionStatus.Cancelled;
  }

  get isTrial(): boolean {
    return this.licenseType === LicenseType.Trial;
  }

  get isCancelAvailable(): boolean {
    return this.subscriptionStatus === SubscriptionStatus.Active || this.subscriptionStatus === SubscriptionStatus.PastDue;
  }

  get isPaid(): boolean {
    return this.licenseType !== LicenseType.Free;
  }

  get subscriptionMembersCount() {
    return this.subscriptionMembers ? this.subscriptionMembers.length : 0;
  }

  isUpgradeAvailable(licenseType: LicenseType): boolean {
    if (licenseType === this.licenseType) {
      return false;
    }

    if (this.isCancelled && !this.ignoreCancelledExpiration) {
      return false;
    }

    return !this.disableUpdates;
  }

  isDowngradeAvailable(licenseType: LicenseType): boolean {
    return (licenseType < this.licenseType) && this.isUpgradeAvailable(licenseType);
  }
}

export function getLicenseTitle(licenseType: LicenseType): LicenseType | string {
  switch (licenseType) {
    case LicenseType.Team:
      return 'Business';

    case LicenseType.Pro:
      return 'Individual';

    default:
      return licenseType;
  }
}
