export enum Authors {
  Yana = 'Yana',
  Sergey = 'Sergey',
  Dmitry = 'Dmitry',
  Violetta = 'Violetta',
  Tania = 'Tania',
  Daria = "Daria",
  Steve = "Steve"
}

const authorsImages = {
  [Authors.Yana]: '/assets/img/authors/yana.jpg',
  [Authors.Sergey]: '/assets/img/authors/sergey.jpg',
  [Authors.Dmitry]: '/assets/img/authors/dmitry.jpg',
  [Authors.Violetta]: '/assets/img/authors/violetta.jpg',
  [Authors.Tania]: '/assets/img/authors/tania.jpg',
  [Authors.Daria]: '/assets/img/authors/daria.jpg',
  [Authors.Steve]: '/assets/img/authors/steve.jpg',


};

const authorsPosition = {
  [Authors.Yana]: 'Product manager',
  [Authors.Sergey]: 'CEO',
  [Authors.Dmitry]: 'Writer',
  [Authors.Violetta]: 'Customer success',
  [Authors.Tania]: 'Customer support',
  [Authors.Daria]: 'Customer success',
  [Authors.Steve]: 'Guest writer',

};

export function getAuthorImg(name: string) {
  return authorsImages[name];
}

export function getAuthorPosition(name: string) {
  return authorsPosition[name];
}
