export enum Categories {
  Platforms = 'platforms',
  Header = 'header',
  Footer = 'footer',
  Menu = 'menu',
  Blog = 'blog',
}

export enum Actions {
  SignInClicked = 'signin_clicked',
  GoToAppClicked = 'go_to_app_clicked',
  ExtensionClicked = 'extention_clicked',
  MainPageClick = 'main_page_click',
  SideBannerClick = 'side_banner_click',
  SubscribeForBlogClick = 'subscribe_for_blog_click',
  InnerBannerClick = 'inner_banner_click',
  LowerBannerClick  = 'lower_banner_click',
  AudioClick = 'audio_click',
  HeaderBannerClicked = 'header_banner_clicked',
}

export interface GoogleAnalyticsOptions {
  category: string;
  action: string;
}
