import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/shared/guards/auth/auth.guard';
import { PricingResolverService } from '@app/core/services/pricing/pricing-resolver.service';
import { redirectToExternalUrlGuard } from '@app/shared/guards/redirect-to-external-url/redirect-to-external-url.guard';
import { INTERCOM_HELP, INTERCOM_HELP_JIRA } from '@app/shared/constants';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    data: {
      withoutBaseTitle: true,
      title: 'Planyway: Roadmap, Calendar, Time Tracker for Trello, Jira, GitHub',
      tags: [
        {
          property: 'og:title',
          content: 'Planyway: Roadmap, Calendar, Time Tracker for Trello, Jira, GitHub'
        },
        {
          name: 'description',
          content: 'Make project management easier with an all-in-one tool for Trello, Jira and GitHub. Build project roadmaps, improve capacity planning, optimize workload, and use time tracking.'
        },
        {
          property: 'og:description',
          content: 'Make project management easier with an all-in-one tool for Trello, Jira and GitHub. Build project roadmaps, improve capacity planning, optimize workload, and use time tracking.'
        },
        {
          name: 'twitter:title',
          content: 'Planyway: Roadmap, Calendar, Time Tracker for Trello, Jira, GitHub',
        },
        {
          name: 'twitter:description',
          content: 'Make project management easier with an all-in-one tool for Trello, Jira and GitHub. Build project roadmaps, improve capacity planning, optimize workload, and use time tracking.',
        },
      ]
    }
  },
  {
    path: 'jira/blog/how-to-build-jira-roadmap',
    redirectTo: 'blog/roadmap-planner'
  },
  {
    path: 'jira',
    loadChildren: () => import('./pages/jira/jira.module').then(m => m.JiraModule),
    data: {
      withoutBaseTitle: true,
      title: 'Resource Planning and Portfolio Management for Jira',
      tags: [
        {
          property: 'og:title',
          content: 'Resource Planning and Portfolio Management for Jira',
        },
        {
          name: 'description',
          content: 'Use Planyway for Jira for visual resource planning, project portfolio management, and time tracking to hit all deadlines and deliver results on time.',
        },
        {
          property: 'og:description',
          content: 'Use Planyway for Jira for visual resource planning, project portfolio management, and time tracking to hit all deadlines and deliver results on time.',
        },
      ]
    },
  },
  {
    path: 'trello',
    loadChildren: () => import('./pages/trello/trello.module').then(m => m.TrelloModule),
    data: {
      withoutBaseTitle: true,
      title: 'Planyway: team calendar, timeline, time tracking for Trello',
      tags: [
        {
          property: 'og:title',
          content: 'Planyway: team calendar, timeline, time tracking for Trello',
        },
        {
          name: 'description',
          content: 'Team calendar simplifies the resource planning and helps to stay organized despite a range of daily tasks. Schedule in Trello to get more done on time. – make project planning a seamless and flawless process.',
        },
        {
          property: 'og:description',
          content: 'Team calendar simplifies the resource planning and helps to stay organized despite a range of daily tasks. Schedule in Trello to get more done on time. – make project planning a seamless and flawless process.',
        },
        {
          name: 'twitter:title',
          content: 'Planyway: team calendar, timeline, time tracking for Trello',
        },
        {
          name: 'twitter:description',
          content: 'Team calendar simplifies the resource planning and helps to stay organized despite a range of daily tasks. Schedule in Trello to get more done on time. – make project planning a seamless and flawless process.',
        },
      ]
    },
  },
  {
    path: 'github',
    loadChildren: () => import('./pages/platforms/github/github.module').then(m => m.GitHubModule),
    data: {
      withoutBaseTitle: true,
      title: 'Visual project management in GitHub: Roadmap, Calendar, Time Tracking',
      tags: [
        {
          property: 'og:title',
          content: 'Visual project management in GitHub: Roadmap, Calendar, Time Tracking',
        },
        {
          name: 'description',
          content: 'Visualize GitHub projects with Planyway advanced roadmaps to make it easy to plan work, delegate tasks to team members, and coordinate deadlines',
        },
        {
          property: 'og:description',
          content: 'Visualize GitHub projects with Planyway advanced roadmaps to make it easy to plan work, delegate tasks to team members, and coordinate deadlines',
        }
      ]
    },
  },
  {
    path: 'pricing',
    loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule),
    data: {
      withoutBaseTitle: true,
      title: 'Planyway Pricing: Pro, Team, and Enterprise plans',
      tags: [
        {
          property: 'og:title',
          content: 'Planyway Pricing: Pro, Team, and Enterprise plans',
        },
        {
          name: 'description',
          content: 'Planyway offers a 14-day free trial of all premium features. Choose the right paid plan for you and your team or keep going with the free version.',
        },
        {
          property: 'og:description',
          content: 'Planyway offers a 14-day free trial of all premium features. Choose the right paid plan for you and your team or keep going with the free version.',
        }
      ]
    }
  },
  {
    path: 'calendar-chrome-extension',
    loadChildren: () => import('./pages/extension-page/extension.module').then(m => m.ExtensionModule),
    data: {
      withoutBaseTitle: true,
      title: 'Schedule team work smarter with Planyway Chrome Extension',
      tags: [
        {
          property: 'og:title',
          content: 'Schedule team work smarter with Planyway Chrome Extension',
        },
        {
          name: 'description',
          content: 'All-in-one team calendar for Trello to easily manage work tasks, within calendar or timeline view, with reports and time tracking inside.',
        },
        {
          property: 'og:description',
          content: 'All-in-one team calendar for Trello to easily manage work tasks, within calendar or timeline view, with reports and time tracking inside.',
        }
      ]
    }
  },
  {
    path: 'pricing/payment',
    loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentModule),
    canActivate: [AuthGuard],
    resolve: {
      pricing: PricingResolverService
    }
  },
  {
    path: 'templates',
    loadChildren: () => import('./pages/use-cases/use-cases.module').then(m => m.UseCasesModule),
    data: {
      title: 'Planyway best use-cases for teams of all types',
      withoutBaseTitle: true,
      tags: [
        {
          property: 'og:title',
          content: 'Planyway best use-cases for teams of all types',
        },
        {
          name: 'description',
          content: 'Discover the best ways to use Planyway in your team. It suits a wide variety of industries and professional fields perfectly well.',
        },
        {
          property: 'og:description',
          content: 'Discover the best ways to use Planyway in your team. It suits a wide variety of industries and professional fields perfectly well.',
        }
      ]
    }
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
    data: {
      withoutBaseTitle: true,
      title: 'Planyway | Contact us to get answers to any questions',
      tags: [
        {
          property: 'og:title',
          content: 'Planyway | Contact us to get answers to any questions',
        },
        {
          name: 'description',
          content: 'Contact Planyway Team to get answers to all of your questions. We\'re happy to help you anytime! Check our Help Center to learn more about our features.',
        },
        {
          property: 'og:description',
          content: 'Contact Planyway Team to get answers to all of your questions. We\'re happy to help you anytime! Check our Help Center to learn more about our features.',
        }
      ]
    }
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    data: {
      title: 'Profile',
      tags: [
        {
          name: 'description',
          content: 'Planyway is Trello Team Calendar Power-Up and Chrome Extension. It allows you to visually manage team and personal schedule across multiple projects to deliver work on time.'
        },
        {
          property: 'og:description',
          content: 'Planyway is Trello Team Calendar Power-Up and Chrome Extension. It allows you to visually manage team and personal schedule across multiple projects to deliver work on time.'
        }
      ]
    }
  },
  {
    path: 'legal',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalModule),
    data: {}
  },
  {
    path: 'help/features/recurring-tasks-in-trello',
    pathMatch: 'full',
    redirectTo: 'blog/recurring-tasks-in-trello'
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blog/components/strapi/strapi.module').then(m => m.StrapiModule),
  },
  {
    path: 'blog/teamwork-project-management',
    pathMatch: 'full',
    redirectTo: 'blog/rolling-wave-planning'
  },
  {
    path: 'blog/project-charter-rough-estimate',
    pathMatch: 'full',
    redirectTo: 'blog/project-charter-elements'
  },
  {
    path: 'blog/project-stakeholders-risks-delivery-outcomes-kpi',
    pathMatch: 'full',
    redirectTo: 'blog/project-charter-elements'
  },
  {
    path: 'blog/project-management-resources-and-project-scope-statement',
    pathMatch: 'full',
    redirectTo: 'blog/project-charter-elements'
  },
  {
    path: 'jira/help',
    loadChildren: () => import('./pages/failure-page/failure-page.module').then(m => m.FailurePageModule),
    canActivate: [redirectToExternalUrlGuard],
    data: {
      externalUrl: INTERCOM_HELP_JIRA
    }
  },
  {
    path: 'help',
    canActivate: [redirectToExternalUrlGuard],
    loadChildren: () => import('./pages/failure-page/failure-page.module').then(m => m.FailurePageModule),
    data: {
      externalUrl: INTERCOM_HELP
    }
  },
  {
    path: 'jira/features',
    loadChildren: () => import('./pages/jira-features/jira-features.module').then(m => m.JiraFeaturesModule),
    data: {
      title: 'Planyway for Jira: Features and Capabilities',
      tags: [
        {
          property: 'og:title',
          content: 'Planyway for Jira: Features and Capabilities'
        },
        {
          name: 'description',
          content: 'Plan team resources cost-effectively through setting issue statuses, estimations, milestones, time tracking and jira reporting. Check all Planyway for Jira features here.'
        },
        {
          property: 'og:description',
          content: 'Plan team resources cost-effectively through setting issue statuses, estimations, milestones, time tracking and jira reporting. Check all Planyway for Jira features here.'
        },
        {
          name: 'twitter:title',
          content: 'Planyway for Jira: Features and Capabilities',
        },
        {
          name: 'twitter:description',
          content: 'Plan team resources cost-effectively through setting issue statuses, estimations, milestones, time tracking and jira reporting. Check all Planyway for Jira features here.',
        },
      ]
    }
  },
  {
    path: 'features',
    loadChildren: () => import('./pages/features/features.module').then(m => m.FeaturesModule),
    data: {
      title: 'All Planyway features & capabilities',
      tags: [
        {
          property: 'og:title',
          content: 'All Planyway features & capabilities'
        },
        {
          name: 'description',
          content: 'All you were missing in Trello: team timeline and calendar, project roadmap, time tracking, dependencies, subtasks, Google Calendar sync'
        },
        {
          property: 'og:description',
          content: 'All you were missing in Trello: team timeline and calendar, project roadmap, time tracking, dependencies, subtasks, Google Calendar sync'
        },
        {
          name: 'twitter:title',
          content: 'All Planyway features & capabilities',
        },
        {
          name: 'twitter:description',
          content: 'All you were missing in Trello: team timeline and calendar, project roadmap, time tracking, dependencies, subtasks, Google Calendar sync',
        },
      ]
    }
  },
  {
    path: 'cancel_subscription',
    loadChildren: () => import('./pages/cancel-subscription/cancel-subscription.module').then(m => m.CancelSubscriptionModule),
    data: {
      title: 'Planyway - Calendar for Trello',
      tags: [
        {
          name: 'description',
          content: 'Planyway. Manage tasks in convenient calendar view.'
        },
        {
          property: 'robots',
          content: 'NOINDEX, NOFOLLOW'
        }
      ]
    }
  },
  {
    path: 'goodbye',
    loadChildren: () => import('./pages/goodbye/goodbye.module').then(m => m.GoodbyeModule),
    data: {
      title: 'Planyway - Calendar for Trello',
      tags: [
        {
          name: 'description',
          content: 'Planyway. Manage tasks in convenient calendar view.'
        },
        {
          property: 'robots',
          content: 'NOINDEX, NOFOLLOW'
        }
      ]
    }
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/failure-page/failure-page.module').then(m => m.FailurePageModule),
    data: {
      title: 'Payment Failure - Planyway',
      tags: [
        {
          name: 'description',
          content: 'Planyway. Plan it your way right in Trello.'
        },
      ]
    }
  },
  {
    path: 'templates',
    loadChildren: () => import('./pages/templates-landing/templates-landing.module').then(m => m.TemplatesLandingPageModule),
    data: {
      title: 'Planyway team calendar for online education',
      tags: [
        {
          name: 'description',
          content: 'Simple way to manage the online educational process and stay productive.'
        },
        {
          property: 'og:title',
          content: 'Planyway team calendar for online education'
        },
        {
          property: 'og:description',
          content: 'Simple way to manage the online educational process and stay productive.'
        }
      ]
    }
  },
  {
    path: '**',
    redirectTo: '/error'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      initialNavigation: 'enabledBlocking',
      scrollOffset: (() => [0, 120])
    })
  ],
  providers: [AuthGuard],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
