import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, Location } from '@angular/common';
import { NavigationStart, Router } from '@angular/router';
import { fromEvent, Subject, timer, zip } from 'rxjs';
import {
  delayWhen,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  takeUntil,
} from 'rxjs/operators';

import {
  BusyService,
  IntercomService,
  ProgressService,
  SeoService,
  WINDOW,
} from '@app/core/services';
import { ProgressMode } from '@app/shared/models/progress.interface';
import { APP_VERSION } from '@app/shared/constants';
import { environment } from '@env/environment';
import { PlatformService } from '@app/core/services/platform/platform.service';
import { Platform as ProductPlatform } from '@app/core/services/platform/platform.model';
import { untilDestroyed } from '@app/shared/operators';
import { MouseflowService } from '@app/core/services/mouseflow/mouseflow.service';
import { UserService } from '@app/core/services/user/user.service';
import { SubscriptionService } from '@app/core/services/subscription/subscription.service';
import { ProfitWellRetainService } from './core/services/profitwell-retain/profitwell-retain.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import { BuildTarget } from '@env';

declare let __webpack_public_path__: any;
if (environment.target === BuildTarget.Dev || environment.target === BuildTarget.Prod) {
  __webpack_public_path__ = `${environment.deployUrl}${APP_VERSION}/`;
}

if (environment.target === BuildTarget.Local) {
  __webpack_public_path__ = `${environment.deployUrl}/`
}

if (environment.target === BuildTarget.Serve) {
  __webpack_public_path__ = ''
}

const SvgIcons = {
  'star': '/assets/icons/star.svg',
  'star_half': '/assets/icons/star_half.svg',
  'star_border': '/assets/icons/star_border.svg',
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeOut', [
      state('in', style({ opacity: 1 })),
      transition('* => void', [animate(350, style({ opacity: 0 }))]),
    ]),
    trigger('slideDown', [
      state('in', style({ transform: 'translateY(0)', opacity: 1 })),
      transition('* => void', [animate(300, style({ transform: 'translateY(100%)', opacity: 0 }))]),
    ]),
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  progressMode: ProgressMode;
  progressValue: number;
  isLoading: boolean;
  appVersion: string = APP_VERSION;

  isCookiesAccepted: boolean;
  isIntercomHover: boolean = false;

  isAppBusy$ = this._busySrv.isAppBusy$;

  private _destroy$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(WINDOW) private _window: Window,
    private _busySrv: BusyService,
    private _domSanitizer: DomSanitizer,
    private _intercomSrv: IntercomService,
    private _location: Location,
    private _matIconRegistry: MatIconRegistry,
    private _mouseflowSrv: MouseflowService,
    private _platform: Platform,
    private _platformSrv: PlatformService,
    private _progressSrv: ProgressService,
    private _renderer2: Renderer2,
    private _router: Router,
    private _seo: SeoService,
    private _subscriptionSrv: SubscriptionService,
    private _profitwellRetainSrv: ProfitWellRetainService,
    private _userSrv: UserService,
  ) {

    if (this._platform.isBrowser) {
      this.isCookiesAccepted = <boolean>JSON.parse(this._window.localStorage.getItem('pwCookiesAccepted') || 'false');

      this.isAppBusy$
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe(isBusy => this._renderer2.setStyle(this._document.body, 'overflow', isBusy ? 'hidden' : 'unset'));

      Object.entries(SvgIcons).forEach(([ name, url ]) => this._matIconRegistry.addSvgIcon(name, this._domSanitizer.bypassSecurityTrustResourceUrl(url)))

      gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
    } else {
      Object.entries(SvgIcons).forEach(([ name, url ]) => this._matIconRegistry.addSvgIconLiteral(name, this._domSanitizer.bypassSecurityTrustHtml('<svg></svg>')))

      this.isCookiesAccepted = true;
    }
  }

  ngOnInit(): void {
    if (this._window.localStorage.getItem('planywayIsInstallSent') === null) {
      this._window.localStorage.setItem(
        'planywayIsInstallSent',
        `${
          !!this._window.localStorage.getItem('planywayUnauthorizedId')
        }`,
      );
    }

    this._checkLocation(this._platformSrv.currentPlatform);
    this._mouseflowSrv.init();
    this._profitwellRetainSrv.init();
    this._seo.init();
    this._initProgressBar();

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        pluck('url'),
        map((url: string) => this._router.parseUrl(url)),
      )
      .subscribe(parsedUrl => {
        let path = parsedUrl.root.children.primary?.segments[0].path;

        // если блог, используем второй сегмент URL
        // т.к. он может быть jira
        // во всех других случаях - используем Trello
        // имеется ввиду planyway.com/blog/jira. В этом случае надо корректно определить
        // что текущая платформа - именно jira
        if (path === 'blog') {
          path = parsedUrl.root.children.primary?.segments[1]?.path ?? path;
        }

        const platform =
          (path as ProductPlatform) || ProductPlatform.Trello;

        if (this._platformSrv.currentPlatform !== platform) {
          this._platformSrv.setCurrentPlatform(platform);
        }
      });

    zip(
      this._userSrv.planywayUser$,
      this._subscriptionSrv.planywaySubscription$
    ).pipe(untilDestroyed(this))
      .subscribe(([ user, licence ]) => {
        this._intercomSrv.updateUserData(user, licence?.licenseType);
      });

    // при изменении идентификатора пользователя planyway в localeStorage
    // (пользователь мог выйти в другой вкладке браузера) перезагружаем приложение
    fromEvent(this._window, 'storage')
      .pipe(
        untilDestroyed(this),
        filter((e: StorageEvent) => e.key === 'planywayUserId'),
        map(() => {
          this._intercomSrv.shutdownWidget();
        })
      ).subscribe(() => {
        this._window.location.reload();
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

  onCloseCookieNotification() {
    this._window.localStorage.setItem('pwCookiesAccepted', 'true');
    this.isCookiesAccepted = true;
  }

  loadIntercom() {
    if (!this.isIntercomHover) {
      this.isIntercomHover = true;

      this._intercomSrv.injectIntercomScript().then(() => {
        setTimeout(() => {
          const facade = this._document.getElementById('intercom-messaging-facade');
          if (facade) {
            facade.parentNode.removeChild(facade);
          }
        }, 3000);
      }).catch(() => {
        this.isIntercomHover = false;
      });
    }
  }

  private _checkLocation(platform: string) {
    const routerPrimaryUrlSegmentGroup = this._router.parseUrl(this._location.path()).root.children.primary;

    if (!routerPrimaryUrlSegmentGroup) {
      return;
    }

    const urlPlatform = routerPrimaryUrlSegmentGroup.segments[0].path || ProductPlatform.Trello;

    if (urlPlatform === platform) {
      return;
    }

    let currentPlatform: ProductPlatform;

    switch (urlPlatform) {
      case ProductPlatform.Jira:
        currentPlatform = ProductPlatform.Jira;
        break;
      case ProductPlatform.Extension:
        currentPlatform = ProductPlatform.Extension;
        break;
      case ProductPlatform.GitHub:
        currentPlatform = ProductPlatform.GitHub;
        break;
      case ProductPlatform.Trello:
      default:
        currentPlatform = ProductPlatform.Trello;
    }

    return this._platformSrv.setCurrentPlatform(currentPlatform);
  }

  private _initProgressBar() {
    this._progressSrv.init();

    this._progressSrv.progressMode$
      .pipe(
        takeUntil(this._destroy$),
        distinctUntilChanged(),
        // Добавляем задержку для плавного скрытия полосы прогресса
        delayWhen(mode => (mode === null ? timer(200) : timer(0))),
      )
      .subscribe(mode => {
        this.progressMode = mode;
        this.isLoading = mode !== null;
      });

    this._progressSrv.progressValue$.pipe(takeUntil(this._destroy$)).subscribe(value => {
      this.progressValue = value;
    });
  }
}
