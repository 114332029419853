import { Injectable, Inject, PLATFORM_ID, isDevMode, OnDestroy } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';
import { Observable, zip } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { WINDOW } from '@app/core/services/window/window.service';
import { AccountService, HttpService, ScriptService, UserService } from '@app/core/services';
import { MOUSEFLOW_CONFIG_URL, MOUSEFLOW_SCRIPT_SRC } from '@app/shared/constants';
import { AuthService } from '@app/core/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MouseflowService implements OnDestroy {
  private _isBrowser: boolean;
  private _isTelemetryAllowed = this._accountSrv.telemetryAllowed;

  constructor(
    @Inject(WINDOW) private _window: Window|any,
    @Inject(PLATFORM_ID) platformId,
    private _accountSrv: AccountService,
    private _authSrv: AuthService,
    private _http: HttpService,
    private _platform: Platform,
    private _scriptSrv: ScriptService
  ) {
    this._isBrowser = isPlatformBrowser(platformId);
  }

  get mouseflow() {
    return this._window.mouseflow;
  }

  get mfq() {
    this._window._mfq = this._window._mfq || [];

    return this._window._mfq;
  }

  init() {
    zip(
      this._getConfig(),
      this._authSrv.isAuthenticatedSubject.pipe(shareReplay(1))
    ).pipe(
      first(),
      map(([ response, _ ]: [any, boolean]) => {
        const config = response;

        if (!config || !config.enabled || Math.random() > config.rate) {
          // Запись отключена для этого типа приложения или пользователь не попал в группу включенных
          return false;
        }
        else {
          return true;
        }
      }),
      filter(enabled => enabled),
      map(() => {
        const isNotValidPlatform = !this._isBrowser || this._platform.IOS || this._platform.ANDROID;

        if (isNotValidPlatform || isDevMode() || !this._isTelemetryAllowed) {
          return false;
        }

        return true;
      }),
      filter(valid => valid),
      switchMap(() => {
        this.mfq.push([ 'config', 'autoStart', false ]);

        return this._scriptSrv.load(MOUSEFLOW_SCRIPT_SRC);
      }),
      switchMap(() => this._authSrv.isAuthenticatedSubject.pipe(shareReplay(1))),
      filter(isAuthenticated => isAuthenticated === false),
      tap(() => {
        this._window._mfq = this._window._mfq || [];

        if (this.mouseflow) {
          this.mouseflow.start();
        }
      })
    ).subscribe();
  }

  private _getConfig(): Observable<JSON> {
    return this._http.get(MOUSEFLOW_CONFIG_URL);
  }

  ngOnDestroy() {}
}
