import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotificationType } from '@app/shared/models';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private _snackBar: MatSnackBar) {
  }

  show(type: NotificationType, message: string, duration = 10000, verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'center') {
    return this._snackBar.open(message, type, {
      duration,
      verticalPosition,
      horizontalPosition,
      panelClass: type
    });
  }
}
