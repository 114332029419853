import { DOCUMENT } from '@angular/common';
import { getUuidDate } from '@planyway-common/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import {
  ScriptService,
  TelemetryService,
  WINDOW
} from '@app/core/services';
import {
  INTERCOM_API_BASE,
  INTERCOM_WORKSPACE_ID
} from '@app/shared/constants';
import {
  AppType,
  ContactUsSource,
  LicenseType,
  PlanywayUser,
  TelemetryEvents
} from '@app/shared/models';

const INTERCOM_INJECT_CODE = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/yzpc1nmz';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

@Injectable({
  providedIn: 'root',
})
export class IntercomService implements OnDestroy {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    @Inject(WINDOW) private _window: Window|any,
    private _platform: Platform,
    private _scriptSrv: ScriptService,
    private _telemetrySrv: TelemetryService,
  ) {
  }

  private get _intercomWidget() {
    return this._window.Intercom;
  }

  private get _intercomSettings() {
    return {
      api_base: INTERCOM_API_BASE,
      app_id: INTERCOM_WORKSPACE_ID,
      'App type': 'Site'
    };
  }

  get isIntercomInitialized() {
    return !!this._intercomWidget;
  }

  async injectIntercomScript() {
    const { isBrowser, ANDROID, IOS } = this._platform;
    if (!isBrowser || ANDROID || IOS) {
      return;
    }

    this._window.intercomSettings = this._intercomSettings;

    await this._scriptSrv.appendRawScript(INTERCOM_INJECT_CODE, 'intercom-js-sdk');

    this._window.Intercom('onShow', () => {
      this._intercomWidget.isOpen = true;
    });

    this._window.Intercom('onHide', () => {
      this._intercomWidget.isOpen = false;
    });
  }

  initIntercom() {
    if (!this.isIntercomInitialized) {
      // Показать иконку загрузки
      const facadeIcon = this._document.getElementById('intercom-messaging-icon');
      if (facadeIcon) {
        facadeIcon.classList.add('intercom-messaging-icon-loading');
      }

      // Загрузить Intercom виджет
      this.injectIntercomScript().then(() => {
        setTimeout(() => {
          const facade = this._document.getElementById('intercom-messaging-facade');
          if (facade) {
            facade.parentNode.removeChild(facade);
          }
        }, 3000);
      });
    }
  }

  updateUserData(user: PlanywayUser, licenseType: LicenseType) {
    if (!this.isIntercomInitialized) {
      return;
    }

    if (!user || !licenseType) {
      return;
    }

    this._intercomWidget('update', {
      email: user.email,
      name: user.name,
      user_id: user.id,
      created_at: Math.floor(getUuidDate(user.id).getTime() / 1000),
      'License type': licenseType
    });
  }

  openWidget() {
    if (!this.isIntercomInitialized || this._intercomWidget.isOpen) {
      return;
    }

    this._intercomWidget('show');
  }

  shutdownWidget() {
    if (!this.isIntercomInitialized) {
      return;
    }

    this._intercomWidget('shutdown');
  }

  contactSupport(source: ContactUsSource) {
    this._telemetrySrv.trackEvent(TelemetryEvents.GeneralEvents.ContactUsClicked, {
      'Where': source,
    });

    this.openWidget();
  }

  ngOnDestroy() {}
}
