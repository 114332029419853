import { Inject, Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

import {
  STANDALONE_URL,
  EXTENSION_URL,
  JIRA_MARKETPLACE_URL,
  APP_STORE_URL,
  GOOGLE_PLAY_URL,
  JIRA_PRICING_URL,
  GITHUB_STANDALONE_URL,
  GITHUB_MARKETPLACE_URL
} from '@app/shared/constants';
import { WINDOW } from '@app/core/services/window/window.service';
import { SeoService } from '@app/core/services/seo/seo.service';
import { TelemetryService } from '@app/core/services/telemetry/telemetry.service';
import { AppType, ExtensionEvents } from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class BrowserService {
  constructor(
    @Inject(WINDOW) private _window: Window | any,
    private _seoSrv: SeoService,
    private _telemetrySrv: TelemetryService,
    private _platform: Platform,
  ) {}

  get extensionUrl(): string {
    return this.appendUtmTags(EXTENSION_URL);
  }

  get standaloneUrl() {
    return this.appendUtmTags(this.storeUrl);
  }

  get jiraMarketPlaceUrl() {
    return this.appendUtmTags(JIRA_MARKETPLACE_URL);
  }

  get jiraMarketPlacePricingUrl() {
    return this.appendUtmTags(JIRA_PRICING_URL);
  }

  get googlePlayUrl(): string {
    return this.appendUtmTags(GOOGLE_PLAY_URL);
  }

  get appStoreUrl(): string {
    return this.appendUtmTags(APP_STORE_URL);
  }

  get githubMarketPlaceUrl() {
    return this.appendUtmTags(GITHUB_MARKETPLACE_URL);
  }

  installExtension() {
    this._window.open(this.extensionUrl, '_blank');
    this._telemetrySrv.trackEvent(ExtensionEvents.ExtensionInstallClicked);
  }

  openStandalone() {
    this._window.open(this.standaloneUrl, '_self');
  }

  openJiraMarketplace() {
    this._window.open(this.jiraMarketPlaceUrl, '_blank');
  }

  openGitHubStandalone() {
    this._window.open(this.appendUtmTags(GITHUB_STANDALONE_URL), '_blank');
  }

  isMobileDevice(): boolean {
    return this._platform.ANDROID || this._platform.IOS;
  }

  openStore(appType: AppType) {
    switch (appType) {
      case AppType.Android:
        this._window.open(this.googlePlayUrl, '_blank');
        return;
      case AppType.iOS:
        this._window.open(this.appStoreUrl, '_blank');
        return;
    }
  }

  get storeUrl(): string {
    switch (true) {
      case this._platform.IOS:
        return this.appStoreUrl;

      case this._platform.ANDROID:
        return this.googlePlayUrl;

      default:
        return STANDALONE_URL;
    }
  }

  appendUtmTags(urlString: string): string {
    const url = new URL(urlString);

    for (const utmTag in this._seoSrv.utmTags) {
      if (this._seoSrv.utmTags.hasOwnProperty(utmTag)) {
        url.searchParams.append(utmTag, this._seoSrv.utmTags[utmTag]);
      }
    }

    return url.toString();
  }
}
