import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";

import { ScriptService } from '@app/core/services/script/script.service';
import { WINDOW } from '@app/core/services/window/window.service';
import { TelemetryService } from '@app/core/services/telemetry/telemetry.service';
import { LogService } from '@app/core/services/log/log.service';
import { CLOUDPAYMENTS_PUBLIC_ID } from '@app/shared/constants';
import { TelemetryEvents } from '@app/shared/models';

const CLOUDPAYMENTS_SRC = 'https://widget.cloudpayments.ru/bundles/cloudpayments.js';

@Injectable({
  providedIn: 'root',
})
export class CloudPaymentsService implements OnDestroy {

  private _widget;
  private _isInitialized: boolean;

  constructor (
    @Inject(WINDOW) private _window: Window | any,
    private _scriptSrv: ScriptService,
    private _logSrv: LogService,
    private _telemetrySrv: TelemetryService,
  ) {
  }

  Checkout(checkoutToken: string) {
    if (this._isInitialized) {
      return this._openCheckout(checkoutToken);
    }

    return this.init().pipe(
      switchMap(result => {
        if (result) {
          return this._openCheckout(checkoutToken);
        }
        else {
          return of({
            success: false,
            message: 'Can not load CloudPayments Checkout'
          });
        }
      })
    );
  }

  _openCheckout(checkoutToken: string) {
    const helper = new JwtHelperService();
    const paymentOptions = helper.decodeToken(checkoutToken);

    return new Observable<{ success: boolean }>(observer => {
      this._widget.charge({
        publicId: CLOUDPAYMENTS_PUBLIC_ID,
        description: paymentOptions.description,
        amount: paymentOptions.amount,
        currency: paymentOptions.currency,
        accountId: paymentOptions.userId,
        invoiceId: paymentOptions.paymentId,
        email: paymentOptions.invoicingEmail,
        skin: "mini",
        data: {
          CloudPayments: {
            recurrent: {
              interval: paymentOptions.interval,
              period: paymentOptions.period,
            },
            checkoutToken
          }
        }
      },
      () => {
        this._telemetrySrv.trackEvent(TelemetryEvents.CheckoutEvents.CheckoutComplete);

        observer.next({ success: true });
        observer.complete();
      },
      () => {
        this._telemetrySrv.trackEvent(TelemetryEvents.CheckoutEvents.CheckoutClosed);

        observer.next({ success: false });
        observer.complete();
      });
    });
  }

  init(): Observable<boolean> {
    if (this._isInitialized) {
      return of(true);
    }

    return this._scriptSrv.load(CLOUDPAYMENTS_SRC).pipe(
      map(loaded => {
        if (loaded) {
          this._widget = new this._window.cp.CloudPayments({language: "ru-RU"});
        }

        this._isInitialized = loaded;

        return loaded;
      }),
      catchError(err => {
        this._logSrv.error(`Failed to initialize CloudPayments SDK:`, err.message);
        this._logSrv.error(err);
        return of(false);
      })
    );
  }

  ngOnDestroy() {}
}
