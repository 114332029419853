export enum AuthEvents {
  AuthRequested = 'Auth Requested',
  AuthConfirmed = 'Auth Confirmed',
  AuthRejected = 'Auth Rejected',
}

export enum AccountEvents {
  AccountAddUserClicked = 'Account Add User Clicked',
  AccountCancelSubscriptionClicked = 'Account Cancel Subscription Clicked',
  AccountChangePaymentMethodClicked = 'Account Change Payment Method Clicked',
  AccountChangeUsersCountClicked = 'Account Change Users Count Clicked',
  AccountChangePlanClicked = 'Account Change Plan Clicked',
  AccountUpgradeClicked = 'Account Upgrade Clicked',
  AccountOpenInvoiceLinkClicked = 'Account Open Invoice Link Clicked',
  AccountPlanDowngraded = 'Account Plan Downgraded',
  AccountPlanUpgraded = 'Account Plan Upgraded',
  AccountQuantityDowngraded = 'Account Quantity Downgraded',
  AccountQuantityUpgraded = 'Account Quantity Upgraded',
  AccountRemoveUserClicked = 'Account Remove User Clicked',
  AccountReplaceUserClicked = 'Account Replace User Clicked',
  AccountUserAdded = 'Account User Added',
  AccountUserRemoved = 'Account User Removed',
  AccountUserReplaced = 'Account User Replaced',
}

export enum CheckoutEvents {
  CheckoutClosed = 'Checkout Closed',
  CheckoutComplete = 'Checkout Complete',
  CheckoutPaymentMethodSelected = 'Checkout Payment Method Selected',
}

export enum ExtensionEvents {
  ExtensionInstallClicked = 'Extension Install Clicked',
}

export enum FeedbackEvents {
  EnterpriseDialogShown = 'Pricing Contact Sales Form Shown',
  EnterpriseDialogClosed = 'Pricing Contact Sales Form Closed',
  EnterpriseDialogSubmitted = 'Pricing Contact Sales Form Sent',
  WebsiteUninstallPageFeedbackSent = 'Website Uninstall Page Feedback Sent',
}

export enum GeneralEvents {
  ContactUsClicked = 'Contact Us Clicked',
}

export enum MainPageEvents {
  FooterAppBadgeClicked = 'Website Footer App Badge Clicked',
  SidenavAppBadgeClicked = 'Website Sidenav App Badge Clicked',
  MainPageClicked = 'Website Main Page Click',
}

export enum OrderSummaryEvents {
  OrderSummaryCancelButtonClicked = 'Order Summary Cancel Button Clicked',
  OrderSummaryConfirmButtonClicked = 'Order Summary Confirm Button Clicked',
  OrderSummaryPaymentPeriodChanged = 'Order Summary Payment Period Changed',
}

export enum PricingEvents {
  PricingGetStartedClicked = 'Pricing Get Started Clicked',
  PricingPageOpened = 'Pricing Page Opened',
  PricingPaymentPeriodChanged = 'Pricing Payment Period Changed',
  PricingPurchaseButtonClicked = 'Pricing Purchase Button Clicked',
  PricingBuyNowClicked = 'Pricing Buy Now Clicked',
}

export enum PageEvents {
  PageView = 'Page View',
}

export enum SubscriptionEvents {
  SubscriptionCancelFeedbackSent = 'Subscription Cancel Feedback Sent',
}

export enum TrialEvents {
  TrialStarted = 'Trial Started',
}

export enum SignInEvents {
  SignInClicked = 'Website Sign In Clicked',
}

export enum WatchEvents {
  WatchClicked = 'Watch Clicked',
}

export enum ReviewEvents {
  ReadMoreRevies = 'Read More Reviews Clicked',
  CustomerReviewClicked = 'Customer Review Clicked',
}

export enum BlogEvents {
  InnerBannerClick = 'Inner Banner Click',
  LowerBannerClick  = 'Lower Banner Click',
  AudioClick = 'Audio Click',
}

export const TelemetryEvents = {
  AccountEvents,
  AuthEvents,
  CheckoutEvents,
  ExtensionEvents,
  FeedbackEvents,
  GeneralEvents,
  PageEvents,
  PricingEvents,
  SignInEvents,
  TrialEvents,
  WatchEvents,
  ReviewEvents,
  BlogEvents,
};
