import { Injectable, OnDestroy } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { AuthService } from '@app/core/services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, OnDestroy {
  constructor(
    private _authSrv: AuthService,
    private _router: Router,
    private _platform: Platform,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this._platform.isBrowser) {
      return true;
    }

    return this._authSrv.checkSignedIn() || this._router.parseUrl('/pricing');
  }

  ngOnDestroy() {}
}
