<div [attr.app-version]="appVersion" style="display: none;"></div>

<div class="app__progress">
  <mat-progress-bar
    [@fadeOut]="'in'"
    *ngIf="isLoading"
    [mode]="progressMode"
    [value]="progressValue"
    color="accent"
  ></mat-progress-bar>
</div>

<router-outlet></router-outlet>

<diV class="app-busy" *ngIf="isAppBusy$ | async">
  <mat-spinner [diameter]="50" color="accent"></mat-spinner>
</diV>

<cookie-warning
  class="app__cookie"
  [@slideDown]="'in'"
  *ngIf="!isCookiesAccepted"
  (close)="onCloseCookieNotification()"
></cookie-warning>

<div id="intercom-messaging-facade">
  <div id="intercom-messaging-icon"
       tabindex="0"
       role="button"
       aria-label="Chat"
       [ngClass]="{
          'intercom-messaging-icon-loading': isIntercomHover
        }"
       (mouseenter)="loadIntercom()"></div>
</div>
