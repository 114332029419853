import { Injectable } from '@angular/core';
import { IPricing } from '@app/shared/models';
import {
  ActivatedRouteSnapshot,
  Resolve,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { PricingService } from './pricing.service';
import { Observable, of } from 'rxjs';
import { mergeMap, take } from "rxjs/operators";
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class PricingResolverService implements Resolve<IPricing> {
  constructor(private _pricingSrv: PricingService, private _router: Router, private _platform: Platform) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<IPricing> | Promise<IPricing> | IPricing {
    if (!this._platform.isBrowser) {
      return of({});
    }

    return this._pricingSrv.loadPricing().pipe(
      take(1),
      mergeMap(pricing => {
        if (pricing) {
          return of(pricing);
        } else {
          this._router.navigate(['/pricing']);
          return of({});
        }
      }),
    );
  }
}
