import dayjs from 'dayjs';
import isEmail from 'is-email';
import { Md5 } from 'ts-md5';

const stringUrlRegex = '^((https|http|ftp|rtsp|mms)?://)'
  + '?(([0-9a-zA-Z_!~*\'().&=+$%-]+: )?[0-9a-zA-Z_!~*\'().&=+$%-]+@)?'
  + '(([0-9]{1,3}\.){3}[0-9]{1,3}'
  + '|'
  + '([0-9a-zA-Z_!~*\'()-]+\.)*'
  + '([0-9a-zA-Z][0-9a-zA-Z-]{0,61})?[0-9a-zA-Z]\.'
  + '[a-zA-Z]{2,6})'
  + '(:[0-9]{1,4})?'
  + '((/?)|'
  + '(/[0-9a-zA-Z_!~*\'().;?:@&=+$,%#-]+)+/?)$';

/**
 * Расчитывает гибкую цену для лицензии
 *
 * @param {number} basePrice исходная цена
 * @param {number} quantity количество лицензий
 */
export function getLegacyFlexLicensePrice(basePrice: number, quantity: number) {
  if (quantity < 11) {
    return Math.round(basePrice * 3 * 100) / 100;
  }

  if (quantity < 51) {
    return Math.round(basePrice * quantity * 100) / 100;
  }

  return Math.round(basePrice * quantity * 1.4 * 100) / 100;
}

/**
 * Расчитывает гибкую цену для лицензии
 *
 * @param {number} basePrice исходная цена
 * @param {number} quantity количество лицензий
 * @param {string} priceModel модель расчёта цены
 */
export function getFlexLicensePrice(basePrice: number, quantity: number, priceModel: string) {
  switch (priceModel) {
    case 'TEAM_$15x10':
      return getLegacyFlexLicensePrice(basePrice, quantity);

    default:
      if (quantity <= 5) {
        return Math.round(basePrice * 2 * 100) / 100;
      }

      if (quantity <= 50) {
        return Math.round((getFlexLicensePrice(basePrice, 5, priceModel) + (quantity - 5) * basePrice) * 100) / 100;
      }

      return Math.round((getFlexLicensePrice(basePrice, 50, priceModel) + basePrice * 1.4 * (quantity - 50)) * 100) / 100;
  }
}

/**
 * Возвращает интервал в днях между двумя заданными датами
 *
 * @param {Date|string} date1 первая дата
 * @param {Date|string} date2 вторая дата
 *
 * @returns {number} интервал в днях
 */
export function getIntervalDays(date1, date2) {
  return dayjs(date2).diff(dayjs(date1), 'day');
}

export async function delayPromise(timeout) {
  await new Promise(resolve => setTimeout(resolve, timeout));
}

export function validateEmail(emailString: string): boolean {
  return isEmail(emailString);
}

export function isUrl(string: string): boolean {
  const urlRegex = new RegExp(stringUrlRegex);

  return urlRegex.test(string);
}

/**
 * Возвращает случайную группу на основе заданной строки
 *
 * @param {string} str исходная строка
 * @param {number} groupSize размер группы
 */
export function getRandomGroup(str: string, groupSize: number): number {
  const hash = Md5.hashStr(str);
  const sum = hash.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

  return sum % groupSize;
}

/**
 * Проверяет, является ли строка GUID
 * @param guid {string} - строка для проверки
 * @returns boolean
 */
export function isGUID(guid: string): boolean {
  return new RegExp('/^([0-9A-Fa-f]{8}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{4}[-]?[0-9A-Fa-f]{12})$/gm').test(guid);
}
