import { Observable } from 'rxjs';

export function loadFileAsDataUrl(file): Observable<{ name: string, content: string }> {
  return Observable.create((observer) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      observer.next({
        name: file.name,
        content: fileReader.result
      });
      observer.complete();
    };

    fileReader.onerror = error => observer.error(error);

    fileReader.readAsDataURL(file);
  });
}

export function findClosestQuantity(availableQuantities: number[], quantity: number): number {
  return availableQuantities.find(availableQuantity => availableQuantity >= quantity) ?? availableQuantities.slice(-1)[0];
}

export function capitalizeFirstLetter(str: string): string {
  return str ? str[0].toUpperCase() + str.slice(1) : null;
}

export function getSrcSet(assetsPath: string, imageName: string): string {
  return `${assetsPath}/${imageName}.png 1x, ${assetsPath}/${imageName}@2x.png 2x, ${assetsPath}/${imageName}@3x.png 3x`;
}

export function getSrc(assetsPath: string, imageName: string): string {
  return `${assetsPath}/${imageName}.png`;
}

export function loadComponentMedia(elementRef: HTMLElement, mediaType: 'img' | 'source') {
  const mediaList = Array.from(elementRef.getElementsByTagName(mediaType));

  mediaList.forEach(media => {
    const mediaSource = media?.dataset?.src;

    if (mediaSource) {
      media.src = mediaSource;
    }
  });
}

export function is2KScreen(): boolean {
  return window.screen.availWidth > 1920;
}

export function getCommonBoardCount(user, boardIds: string[]): number {
  const commonBoards = user.boardIds.filter(boardId => boardIds.includes(boardId));
  return commonBoards.length;
}

export function isPlanywayUserId(userId: string): boolean {
  const planywayUserIdRexExp = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$');
  return planywayUserIdRexExp.test(userId);
}

export function debounce(delay: number = 300): MethodDecorator {
  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const timeoutKey = Symbol();

    const original = descriptor.value;

    descriptor.value = function (...args) {
      clearTimeout(this[timeoutKey]);
      this[timeoutKey] = setTimeout(() => original.apply(this, args), delay);
    };

    return descriptor;
  };
}
