import AppPackage from '../../../package.json';
import { BuildTarget } from '@env';
import { environment } from '@env/environment';

export const APP_VERSION = AppPackage.version;

const trelloApiKeyDev = 'a6c21ff4879a00908432037c342b1de0';
const trelloApiKeyProd = 'c05e9168828198b995a2175af18e5686';
export const TRELLO_API_KEY = environment.target === BuildTarget.Prod ? trelloApiKeyProd : trelloApiKeyDev;

const trelloAppDevName = 'Planyway Calendar Dev';
const trelloAppProdName = 'Planyway Calendar';
export const TRELLO_APP_NAME = environment.target === BuildTarget.Prod ? trelloAppProdName : trelloAppDevName;
export const GOOGLE_CLIENT_ID = environment.target === BuildTarget.Prod
    ? '442776835437-hdtlsqk5d7vudej196jqomact0p2onj8.apps.googleusercontent.com'
    : '693157308091-abu32f6c7cc5hqre866gs1acf9r2aolt.apps.googleusercontent.com';

const BackendBaseUrlDev = 'https://dev.planyway.com/api/b';
const BackendBaseUrlProd = 'https://planyway.com/api/b';

export const BACKEND_URL = environment.target === BuildTarget.Prod
  ? BackendBaseUrlProd
  : BackendBaseUrlDev;

export const COMMON_CDN_URL = `${environment.cdnUrl}/common`;

const TrelloApiVersion = 1;
const TrelloApiEndpoint = 'https://api.trello.com';
export const TRELLO_API_BASE_URL = `${TrelloApiEndpoint}/${TrelloApiVersion}`;

export const TRELLO_ORGANIZATION_LOGO_BASE_URL = 'https://trello-logos.s3.amazonaws.com'

const DevPaddleVendorId = 29468;
const ProdPaddleVendorId = 28457;

export const PADDLE_VENDOR_ID = environment.target === BuildTarget.Prod ? ProdPaddleVendorId : DevPaddleVendorId;

const CloudPaymentsIdDev = 'pk_91ba5bec381361715cbd0469a5ab8';
const CloudPaymentsIdProd = 'pk_989b82dcb7a68e0785fb476d2ba1c';

export const CLOUDPAYMENTS_PUBLIC_ID = environment.target === BuildTarget.Prod
  ? CloudPaymentsIdProd
  : CloudPaymentsIdDev;

export const ProfitWellRetainEnabled = true;
export const ProfitWellRetainToken = 'fc4d782664aac8bb42bebfaa8e444dfe';

export const FACEBOOK_APP_ID = '964423763704645';
export const FACEBOOK_PROMO = {
  coupon: '',
  discount: 0
};

export const INTERCOM_WORKSPACE_ID =  'yzpc1nmz';
export const INTERCOM_API_BASE = 'https://api-iam.intercom.io';

const AppInsightsInstrumentationKeyDev = '1e8f66e2-6386-4c57-a003-92db9fab68e8';
const AppInsightsInstrumentationKeyProd = '25649807-3376-4c5d-ae98-0d2bf8dd4a5a';
export const AppInsightsInstrumentationKey = environment.target === BuildTarget.Prod
  ?  AppInsightsInstrumentationKeyProd
  :  AppInsightsInstrumentationKeyDev;

const AMPLITUDE_API_KEY_DEV = 'c502c994c3d9996b323785de08d5b4ff';
const AMPLITUDE_API_KEY_PROD = '7b125b529ffb0de07329e198d3b3cbde';
export const AMPLITUDE_API_KEY = environment.target === BuildTarget.Prod ? AMPLITUDE_API_KEY_PROD : AMPLITUDE_API_KEY_DEV;
export const AMPLITUDE_API_ENDPOINT = 'https://api.amplitude.com';

export const MOUSEFLOW_CONFIG_URL = `${COMMON_CDN_URL}/mf-site.json`;
export const MOUSEFLOW_WEBSITE_ID = 'aae61b93-6bb0-4886-85b4-ce4a4353f4cb';
export const MOUSEFLOW_SCRIPT_SRC = `//cdn.mouseflow.com/projects/${MOUSEFLOW_WEBSITE_ID}.js`;

export const FACEBOOK_SRC = 'https://connect.facebook.net/en_US/sdk.js';
export const SLICK_SRC = 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js';
export const SLY_SRC = 'https://cdnjs.cloudflare.com/ajax/libs/Sly/1.6.1/sly.min.js';

export const JIRA_MARKETPLACE_URL = 'https://marketplace.atlassian.com/apps/1221297/?tab=overview&hosting=cloud';
export const JIRA_PRICING_URL = 'https://marketplace.atlassian.com/apps/1221297/planyway-resource-planning-portfolio-management-for-jira?hosting=cloud&tab=pricing';
export const JIRA_ROADMAP_PRICING_URL = 'https://marketplace.atlassian.com/apps/1221297/planyway-project-planner-roadmap-and-time-tracker-for-jira?hosting=datacenter&tab=pricing';

export const EXTENSION_URL = 'https://chrome.google.com/webstore/detail/planyway-calendar-for-tre/kkgaechmpjgbojahkofamdjkaklgbdkc';

const STANDALONE_URL_DEV = 'https://dev.planyway.com/platforms/trello/standalone/last/html/standalone.html';
const STANDALONE_URL_PROD = 'https://planyway.com/app';
export const STANDALONE_URL = environment.target === BuildTarget.Prod ? STANDALONE_URL_PROD: STANDALONE_URL_DEV;

const GITHUB_STANDALONE_URL_DEV = 'https://dev.planyway.com/platforms/github/standalone/last/html/standalone.html';
const GITHUB_STANDALONE_URL_PROD = 'https://planyway.com/github/app';
export const GITHUB_STANDALONE_URL = environment.target === BuildTarget.Prod ? GITHUB_STANDALONE_URL_PROD: GITHUB_STANDALONE_URL_DEV;

export const TEAMS_URL = 'https://appsource.microsoft.com/en-us/product/office/WA200002712';

export const POWERUP_URL = 'https://trello.com/power-ups/58bd1f9aca72f48c8900574f';
export const TIME_TRACKING_POWERUP_URL = 'https://trello.com/power-ups/668627c545b907bff06e212e';

export const GITHUB_MARKETPLACE_URL = 'https://github.com/marketplace/planyway-for-github';

export const CAPTERRA_RATING = 4.5;
export const CAPTERRA_REVIEW_COUNT = 278;
export const CAPTERRA_URL = 'https://www.capterra.com/p/197375/Planyway';
export const GET_APP_URL = 'https://www.getapp.com/project-management-planning-software/a/planyway';
export const SOFTWARE_ADVICE_URL = 'https://www.softwareadvice.com/project-management/planyway-profile/';
export const G2_URL = 'https://www.g2.com/products/planyway/reviews';
export const TRUST_PILOT_URL = 'https://www.trustpilot.com/review/planyway.com';

export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.rubius.planyway';
export const APP_STORE_URL = 'https://apps.apple.com/us/app/planyway-calendar-for-trello/id1398708038';

export const PLANYWAY_FACEBOOK_URL = 'https://www.facebook.com/planyway/';
export const PLANYWAY_LINKEDIN_URL = 'https://www.linkedin.com/company/planyway/';
export const PLANYWAY_YOUTUBE_URL = 'https://www.youtube.com/channel/UCNa_8c_779lhaSnBEFg5M5Q';
export const PLANYWAY_TWITTER_URL = 'https://twitter.com/planywayplanner?lang=en';

export const PLANYWAY_TOKEN_HEADER_NAME = 'x-planyway-token';

export const BASE_TITLE = 'Planyway Team Calendar';

export const DEFAULT_USERS_QUANTITIES = [...Array(101).keys()].slice(1);
export const TEAM_PLAN_QUANTITIES = [...Array(101).keys()].slice(3); // 3 - 100

export const TEAM_QUANTITY_PACKAGES = DEFAULT_USERS_QUANTITIES;

export const ENTERPRISE_QUANTITY_PACKAGES = [
  20,
  30,
  50,
  70,
  100
];

export const MAX_USERS_QUANTITY = 100;

export const DIVIDE_PLANS_RELEASE_DATE = new Date('2021-04-22T04:39:59.518Z');

export const INTERCOM_HELP = 'https://help.planyway.com/en/';
export const INTERCOM_HELP_JIRA = 'https://help.planyway.com/en/collections/8912397-planyway-for-jira';
export const INTERCOM_HELP_GITHUB = 'https://help.planyway.com/en/collections/6442497-planyway-for-github';
export const INTERCOM_HELP_TRELLO = 'https://help.planyway.com/en/collections/3943319-planyway-for-trello';

export const INTERCOM_HELP_GETTING_STARTED = 'https://help.planyway.com/en/articles/8166367-getting-started-guide';
export const INTERCOM_HELP_GETTING_STARTED_STEP_2 = `${INTERCOM_HELP_GETTING_STARTED}#h_73b5436e8e`;
