import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LogService, WINDOW_PROVIDERS } from './services';
import { CookieWarningComponent } from './components';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    CookieWarningComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    RouterModule
  ],
  providers: [
    {
      provide: LogService,
      useValue: console
    },
    ...WINDOW_PROVIDERS
  ],
  exports: [
    CookieWarningComponent
  ]
})
export class CoreModule {
}
