import { LicenseType } from '@app/shared/models/subscription';
import { BillingPeriod } from './billing';

export interface IPaymentData {
  invoicingEmail?: string;
  quantity?: number;
  licenseType: LicenseType;
  paymentPeriod: string;
  coupon?: string;
  preview?: boolean;
}

export const PaymentPeriodNames = {
  [BillingPeriod.Monthly]: 'Monthly',
  [BillingPeriod.Annual]: 'Annual',
  MONTH: 'Monthly',
  YEAR: 'Annual',
};

export const BillingPeriodMap = {
  [BillingPeriod.Monthly]: 'MONTH',
  [BillingPeriod.Annual]: 'YEAR',
};

export const BillingPeriodInverseMap = {
  MONTH: BillingPeriod.Monthly,
  YEAR: BillingPeriod.Annual,
};

export enum PaymentPlatform {
  Paddle = 'PD',
  CloudPayments = 'CP',
}

export enum PaddleCheckoutMode {
  Inline = 'inline',
  Overlay = 'overlay',
}
