import { LicenseType } from '../subscription';
import { CurrencyMark } from '@app/shared/models/pricing/currency.enum';

export enum PaddleCheckoutEvents {
  PaymentMethodSelected = 'Checkout.PaymentMethodSelected',
  Complete = 'Checkout.Complete',
  Close = 'Checkout.Close',
  CouponApplied = 'Checkout.Coupon.Applied',
  CouponRemoved = 'Checkout.Coupon.Remove',
  Loaded = 'Checkout.Loaded',
  VatApplied = 'Checkout.Vat.Applied',
  VatRemove = 'Checkout.Vat.Remove',
}

export enum CloudPaymentsCheckoutEvents {
  Complete = 'Complete',
  Close = 'Close',
}

export enum BillingPeriod {
  Monthly = 'monthly',
  Annual = 'annual'
}

export enum PaymentPeriod {
  Month = 'MONTH',
  Year = 'YEAR',
}

export interface LastPayment {
  amount: number;
  currency: CurrencyMark | string;
  date: Date;
  receiptUrl: string;
}

export interface NextPayment {
  amount: number;
  currency: CurrencyMark | string;
  date: Date;
}

export interface IBilling {
  readonly requirePayment: boolean;
  readonly [BillingPeriod.Monthly]: IBillingPeriod;
  readonly [BillingPeriod.Annual]: IBillingPeriod;
}

export interface IBillingPeriod {
  readonly interval: number;
  isVatIncluded?: boolean;
  price: number;
  period: string;
  currency: string;
  priceModel?: string;
}

export interface IBillingPlan {
  readonly billing: IBilling;
  readonly licenseType: LicenseType;
  readonly isPopular: boolean;
  readonly isVatIncluded: boolean;
  readonly features: Array<{ content: string, extra?: boolean, coming?: boolean, note?: boolean }>;
}

interface IBillingPrice {
  isVatIncluded?: boolean;
  price: number;
  modifier: number;
  currency: string;
  priceModel?: string;
}

export class BillingPlan implements IBillingPlan {
  readonly billing: IBilling;
  readonly licenseType: LicenseType;
  readonly isPopular: boolean;
  readonly features: Array<{ content: string, extra?: boolean, coming?: boolean }>;
  readonly isVatIncluded: boolean;

  constructor(
    licenseType: LicenseType,
    billingData: {
      isVatIncluded: boolean,
      requirePayment: boolean,
      [BillingPeriod.Monthly]: IBillingPrice,
      [BillingPeriod.Annual]: IBillingPrice
    }
  ) {
    this.licenseType = licenseType;
    this.isPopular = licenseType === LicenseType.Team;
    this.isVatIncluded = billingData.isVatIncluded;

    this.billing = {
      requirePayment: billingData.requirePayment,
      [BillingPeriod.Monthly]: {
        isVatIncluded: billingData[BillingPeriod.Monthly].isVatIncluded,
        interval: 1,
        period: 'MONTH',
        price: billingData[BillingPeriod.Monthly].price * billingData[BillingPeriod.Monthly].modifier,
        currency: billingData[BillingPeriod.Monthly].currency,
        priceModel: billingData[BillingPeriod.Monthly].priceModel
      },
      [BillingPeriod.Annual]: {
        isVatIncluded: billingData[BillingPeriod.Annual].isVatIncluded,
        interval: 12,
        period: 'YEAR',
        price: billingData[BillingPeriod.Annual].price * billingData[BillingPeriod.Annual].modifier,
        currency: billingData[BillingPeriod.Annual].currency,
        priceModel: billingData[BillingPeriod.Annual].priceModel
      }
    };
  }
}
