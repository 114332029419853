import { Platform } from '@angular/cdk/platform';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import { EMPTY, from, Observable, of, Subject, zip } from 'rxjs';
import {
  catchError, filter, finalize, map, pairwise, switchMap, take, tap
} from 'rxjs/operators';

import { WINDOW } from '@app/core/services/window/window.service';
import { LogService } from '@app/core/services/log/log.service';
import { HttpService } from '@app/core/services/http/http.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { TelemetryService } from '@app/core/services/telemetry/telemetry.service';
import { BACKEND_URL, TRELLO_API_BASE_URL, TRELLO_API_KEY } from '@app/shared/constants';
import { getCommonBoardCount } from '@app/shared/helpers';
import {
  AccountEvents, BackendResponse, IResponse, ITrelloBoardAndOrganization, ITrelloMember,
  LicenseType, NotificationType, PlanywayUser, RawSubscription, TelemetryEvents, UserUpdatesDTO,
} from '@app/shared/models';
import { IntercomService, SubscriptionService } from '@app/core/services';
import { AuthService } from '@app/core/services/auth/auth.service';
import { UserService } from '@app/core/services/user/user.service';
import { untilDestroyed } from '@app/shared/operators';
import { AccountType } from '@planyway-common/common';
import { forkJoin } from 'rxjs';
import { Account, GoogleAccount, OutlookAccount, TrelloAccount } from './account.model';

const PLANYWAY_AUTH_URL = 'planyway/auth';

enum RequestMethods {
  Get,
  Post,
  Put,
  Delete,
}


@Injectable({
  providedIn: 'root',
})
export class AccountService implements OnDestroy {
  private planywayUserLoadedSubject = new Subject<boolean>();
  readonly planywayUserLoaded$ = this.planywayUserLoadedSubject.asObservable();

  private user: PlanywayUser;
  isUserLoading = false;

  private reloadLicenseSubject = new Subject<string>();
  isLicenseLoading = false;

  planywayToken: string | null = null;
  private trelloToken: string | null = null;
  private googleToken: string | null = null;
  private outlookToken: string | null = null;
  private connectedAccounts: Map<AccountType, Account>;

  constructor(
    @Inject(WINDOW) private _window: Window | any,
    private _authSrv: AuthService,
    private _http: HttpService,
    private _intercomSrv: IntercomService,
    private _logSrv: LogService,
    private _notificationSrv: NotificationService,
    private _platform: Platform,
    private _subscriptionSrv: SubscriptionService,
    private _telemetrySrv: TelemetryService,
    private _userSrv: UserService,
  ) {
    // Загружаем информацию о пользователе Trello
    zip(
      this._authSrv.planywayToken$,
      this._authSrv.planywayAuthToken$,
    ).pipe(
      take(1),
      filter(() => this._platform.isBrowser),
      switchMap(() => this._authSrv.checkSignedIn()),
      switchMap((isSignedIn) => {
        if (!isSignedIn) {
          return this._authSrv.checkSignInByRedirectUrl();
        }

        return of(isSignedIn);
      }),
      filter(isSignedIn => isSignedIn),
      tap(() => this.isUserLoading = true),
      switchMap(() => this._authSrv.planywayUserId$),
      filter(userId => !!userId),
      tap(() => this._subscriptionSrv.loadUserSubscription()),
      switchMap(() => this._userSrv.planywayUser$),
      filter(user => !!user),
      switchMap(user =>
        forkJoin({
          settings: this.loadUserSettings(),
          userData: this._loadAccountData(user.id),
        }),
      ),
      tap(this._clearUserLoadingState),
      catchError(err => {
        this._logSrv.error('Failed to load Trello member: ', err.message);
        this._logSrv.error(err);

        return of(null);
      }),
    ).subscribe();

    this._userSrv.planywayUser$.pipe(untilDestroyed(this)).subscribe(user => this.user = user);

    // Загружаем подписку пользователя Planyway
    this.reloadLicenseSubject.asObservable()
      .pipe(
        filter(() => this._platform.isBrowser),
        tap(() => this.isLicenseLoading = true),
        switchMap(() => {
          this._subscriptionSrv.loadUserSubscription();
          this.loadUserSettings().pipe(take(1)).subscribe();
          return this._subscriptionSrv.planywaySubscription$;
        }),
        catchError(err => {
          this._logSrv.error('Failed to load planyway subscription: ', err.message);
          this._logSrv.error(err);

          return of(null);
        }),
      )
      .subscribe(subscription => {
        this.isLicenseLoading = false;
        this._telemetrySrv.toggleTelemetry.next(this.telemetryAllowed);

        if (subscription) {
          this._telemetrySrv.setBaseProperties({
            'Subscription Users Count': (subscription.addedUserIds || []).length,
          });
        }
      });

    this._subscriptionSrv.planywaySubscription$
        .pipe(pairwise())
        .subscribe(([previousSubscriptionObject, currentSubscriptionObject]) => {
          if (!previousSubscriptionObject || !currentSubscriptionObject) {
            return;
          }

          const licenseData = {
            'Plan': currentSubscriptionObject.licenseType,
            'Payment Period': currentSubscriptionObject.paymentPeriod,
            'Quantity': currentSubscriptionObject.quantity,
          };

          if ((currentSubscriptionObject.licenseType === LicenseType.Team) && (previousSubscriptionObject.licenseType === LicenseType.Pro)) {
            this._notificationSrv.show(NotificationType.Success, 'Your plan successfully upgraded!\nIt may take 5-15 min to activate your subscription');
            this._telemetrySrv.trackEvent(AccountEvents.AccountPlanUpgraded, licenseData);
            return;
          }

          if ((currentSubscriptionObject.licenseType === LicenseType.Pro) && (previousSubscriptionObject.licenseType === LicenseType.Team)) {
            this._notificationSrv.show(NotificationType.Success, 'Your plan successfully downgraded');
            this._telemetrySrv.trackEvent(AccountEvents.AccountPlanDowngraded, licenseData);
            return;
          }

          if (currentSubscriptionObject.quantity > previousSubscriptionObject.quantity) {
            this._notificationSrv.show(NotificationType.Success, 'Users quantity successfully upgraded');
            this._telemetrySrv.trackEvent(AccountEvents.AccountQuantityUpgraded, licenseData);
            return;
          }

          if (currentSubscriptionObject.quantity < previousSubscriptionObject.quantity) {
            this._notificationSrv.show(NotificationType.Success, 'Users quantity successfully downgraded');
            this._telemetrySrv.trackEvent(AccountEvents.AccountQuantityDowngraded, licenseData);
            return;
          }
        });
  }

  /**
   * Флаг телеметрии
   */
  get telemetryAllowed() {
    return this._window.localStorage.hasOwnProperty('planywayTelemetryAllowed')
      ? JSON.parse(this._window.localStorage.getItem('planywayTelemetryAllowed'))
      : true;
  }

  /**
   * Токен Trello
   */
  get token() {
    return this.trelloToken || this.googleToken || this.outlookToken;
  }

  /**
   * Запускает процесс авторизации пользователя
   */
  signIn(providerType: AccountType = AccountType.Trello) {
    this.stopMouseflowRecording();

    return of(providerType).pipe(
      tap(() => {
        this._telemetrySrv.trackEvent(TelemetryEvents.AuthEvents.AuthRequested);
      }),
      switchMap(accountType => from(this._authSrv.openAuthPopupAsync(accountType))),
      filter(token => !!token),
      tap(() => (this.isUserLoading = true)),
      switchMap(token => this._authSrv.signInByProviderToken(token, providerType)),
      filter(userId => !!userId),
      switchMap(userId =>
        forkJoin({
          subsciption: of(this._subscriptionSrv.loadUserSubscription()),
          userData: this._loadAccountData(userId, providerType),
        }),
      ),
      switchMap(() => this._userSrv.planywayUser$),
      filter(user => !!user),
      tap((user) => localStorage.planywayUserId = user.id),
      switchMap(() => this.loadUserSettings()),
      tap(this._clearUserLoadingState),
      catchError(err => {
        this._logSrv.error('Account authorization error:');
        this._logSrv.error(err);

        this.trelloToken = null;
        this.googleToken = null;
        this.outlookToken = null;

        return of(false);
      }),
    );
  }

  /**
   * Запускает процесс авторизации пользователя в текущем окне
   */
  signInByFragment() {
    this.stopMouseflowRecording();

    this._telemetrySrv.trackEvent(TelemetryEvents.AuthEvents.AuthRequested);

    return of(this._authSrv.openTrelloAuthPage()).pipe(
      catchError(err => {
        this._logSrv.error('Trello authorization error:');
        this._logSrv.error(err);

        this.trelloToken = null;
        this.googleToken = null;
        this.outlookToken = null;

        return of(false);
      }),
    );
  }

  /**
   * Выполняет выход пользователя
   */
  signOut() {
    this.startMouseflowRecording();

    this.isUserLoading = true;

    return this._signOutFromPlanyway()
      .pipe(
        filter(isSignedOut => isSignedOut),
        switchMap(() => this._authSrv.signOut()),
        switchMap(() => this._subscriptionSrv.signOut()),
        map(() => {
          this.trelloToken = null;
        }),
        tap(this._clearUserLoadingState),
        tap(() => {
          localStorage.planywayUserId = '';

          this._intercomSrv.shutdownWidget();

          // Перезагружает текущую вкладку
          this._window.location.reload();
        })
      );
  }

  /**
   * Перезагружает лицензию пользователя
   */
  reloadLicense() {
    this._authSrv.planywayUserId$.pipe(take(1)).subscribe(userId => this.reloadLicenseSubject.next(userId));
  }

  /**
   * Загружает информацию о пользователях Trello
   *
   * @param memberIds
   */
  async loadPlanywayMembersAsync(memberIds: string[]): Promise<ITrelloMember[]> {
    const result = [];
    const trelloMemberFields = encodeURIComponent([
      'id',
      'fullName',
      'initials',
      'avatarUrl',
      'username',
      'idOrganizations',
      'idBoards',
    ].join(','));

    const trelloOrganizationFields = encodeURIComponent([
      'name',
      'displayName',
      'logoHash',
      'limits',
      'memberships',
      'prefs',
      'premiumFeatures',
    ].join(','));

    const memberQueries = memberIds.map(id => `/members/${id}?fields=${trelloMemberFields}&organizations=all&organization_fields=${trelloOrganizationFields}`);

    // Разбиваем запросы группами по 20 для обхода ограничения длины URL запроса
    while (memberQueries.length > 0) {
      const subscriptionsMembersChunk = await this._http.batch<ITrelloMember>(TRELLO_API_BASE_URL, memberQueries.splice(0, 20), {
        headers: {
          Authorization: `OAuth oauth_consumer_key="${TRELLO_API_KEY}", oauth_token="${this.token}"`
        }
      }).toPromise();

      result.push(...subscriptionsMembersChunk);
    }

    return result;
  }

  /**
   * Загружает идентификаторы пользователей со всех досок и организаций
   * Сортирует по максимальному числу общих досок с пользователем
   */
  async loadAllCommonMembers(membersCount = 20, options?: any): Promise<any> {
    const memberIds = new Set();

    const { boards, organizations } = await this._http
      .get<IResponse<ITrelloBoardAndOrganization[]>>(`${TRELLO_API_BASE_URL}/member/me`, {
        headers: {
          Authorization: `OAuth oauth_consumer_key="${TRELLO_API_KEY}", oauth_token="${this.trelloToken}"`,
        },
        query: {
          fields: 'id',
          boards: 'open',
          board_fields: 'memberships',
          organizations: 'all',
          organization_fields: 'memberships,name,displayName,logoHash',
        },
      })
      .toPromise();

    for (const board of boards) {
      for (const member of board.memberships) {
        memberIds.add(member.idMember);
      }
    }

    for (const organization of organizations) {
      for (const member of organization.memberships) {
        memberIds.add(member.idMember);
      }
    }

    const currentUserBoardIds = boards.map(b => b.id);
    let response: any = {
      memberIds: Array.from(memberIds)
        .map(memberId => ({
          memberId,
          boardIds: boards
            .filter(({ memberships }) => memberships.filter(member => memberId === member.idMember).length > 0)
            .map(board => board.id),
        }))
        .sort(
          (user1, user2) =>
            getCommonBoardCount(user2, currentUserBoardIds) - getCommonBoardCount(user1, currentUserBoardIds),
        )
        .map(member => member.memberId)
        .slice(0, membersCount),
    };

    if (options?.keepOrganizations) {
      response = {
        memberIds: response.memberIds,
        organizations,
      };
    }

    return response;
  }

  /**
   * Выполняет активацию триала
   */
  activateTrial(): Observable<BackendResponse<RawSubscription>> {
    if (!this.user) {
      return of({
        body: {
          success: false,
          message: 'User not authenticated',
        },
      });
    }

    return this._authSrv.authorizedRequest<BackendResponse<any>>(
      RequestMethods.Post,
      `${BACKEND_URL}/planyway/${this.user.id}/license`,
      {
        body: {
          action: 'activateTrial',
        },
      },
      {
        usePlanywayAuth: true
      }
    ).pipe(
      tap(({ body }) => {
        if (body.success) {
          this.reloadLicense();
        }
      }),
    );
  }

  /**
   * Отправляет результаты churn опроса в бэкенд
   *
   * @param {string} reason причина отмены подписки
   * @param {string} details детали отмены подписки
   *
   * @returns {Promise<void>}
   */
  async sendChurnAsync(reason: string, details: string): Promise<void> {
    try {
      await this._authSrv.authorizedRequest<BackendResponse<any>>(
        RequestMethods.Post,
        `${BACKEND_URL}/planyway/${this.user.id}/acquisition`,
        {
          body: {
            action: 'churn',
            data: {
              reason,
              details,
            },
          },
        },
        {
          usePlanywayAuth: true
        }
      ).toPromise();
    }
    catch {
      // Игнорируем исключение
    }
  }

  /**
   * Загружает настройки пользователя
   */
  loadUserSettings(): Observable<boolean> {
    let isTelemetryAllowed = 'false';

    return this._authSrv.authorizedRequest<BackendResponse<any>>(
        RequestMethods.Post,
        `${BACKEND_URL}/planyway/${this.user.id}/userSettings`,
        {
          body: {
            action: 'get'
          },
        },
        {usePlanywayAuth: true}
      ).pipe(
        catchError(() => EMPTY),
        map(({body: {success, data}}) => {
          if (success) {
            isTelemetryAllowed = data.flags.telemetry;
          }

          return !!success;
        }),
        finalize(() => {
          this._window.localStorage.setItem('planywayTelemetryAllowed', isTelemetryAllowed);
        })
    );
  }

  /**
   * Запрос на изменение данных пользователя
   *
   * @param {UserUpdatesDTO} updates новые данные пользователя
   *
   * @returns {Promise<void>}
   */
   async updateUserProfileAsync(updates : UserUpdatesDTO): Promise<void> {
    this.isUserLoading = true;

    try {
      await this._authSrv.authorizedRequest<BackendResponse<any>>(
        RequestMethods.Post,
        `${BACKEND_URL}/planyway/${this.user.id}/user`,
        {
          body: {
            action: 'update',
            data: updates,
          },
        },
        {
          usePlanywayAuth: true
        }
      ).toPromise();
    }
    catch {
      // Игнорируем исключение
    }
    finally {
      this.isUserLoading = false;
    }
  }

  /**
   * Выполняет выход пользователя из Planyway
   *
   * @returns {Observable<boolean>} true, если выход выполнен успешно, иначе - false
   */
  private _signOutFromPlanyway(): Observable<boolean> {
    return this._authSrv.authorizedRequest<BackendResponse<any>>(
      RequestMethods.Post,
      `${BACKEND_URL}/${PLANYWAY_AUTH_URL}`,
      {
        body: {
          action: 'signOut'
        }
      },
      {
        usePlanywayAuth: true
      }
    ).pipe(
      map(({body: response}) => {
        if (!response.success) {
          this._notificationSrv.show(NotificationType.Error, `Planyway sign out failed. ${response.message}`);
          return false;
        }

        return true;
      }),
    );
  }

  /**
   * Загружает список сторонних аккаунтов, подключенных к текущему аккаунту пользователя Planyway
   *
   * @returns {Observable<void>}
   */
  private _loadConnectedAccounts(userId: string): Observable<void> {
    return this._authSrv.authorizedRequest<BackendResponse<any>>(
      RequestMethods.Get,
      `${BACKEND_URL}/planyway/${userId}/connect`,
      {},
      {
        usePlanywayAuth: true
      }
    ).pipe(
      map(({body: response}) => {
        if (!response.success) {
          this._notificationSrv.show(NotificationType.Error, `Planyway get accounts failed. ${response.message}`);
          return;
        }

          this.connectedAccounts = new Map(response.data.map(account => [account.accountId, account as Account]));
        }),
      );
  }

  /**
   * Обновляет данные подключенного аккаунта
   *
   * @returns {Observable<boolean>} true, если данные подключенного аккаунта успешно загружены, иначе - false
   */
  _loadAccountData(userId: string, accountType: AccountType = AccountType.Trello): Observable<boolean> {
    return this._loadConnectedAccounts(userId).pipe(
      map(() => {
        const allConnectedAccounts = Array.from(this.connectedAccounts.values());

        const trelloAcccount = allConnectedAccounts.find(account => account.accountType === AccountType.Trello);
        this._authSrv.setTrelloAccount(trelloAcccount);

        const primaryAccount = allConnectedAccounts.find(account => account.isPrimary);
        if (!primaryAccount) {
          return false;
        }

        const userIdData = {
          unAuthUserId: null,
          authUserId: (primaryAccount as TrelloAccount)?.trelloMemberId ||
          (primaryAccount as GoogleAccount)?.googleUserId ||
          (primaryAccount as OutlookAccount)?.outlookUserId ||
          null,
          planywayUserId: userId
        }

        this._telemetrySrv.initUserId(userIdData);

        this.trelloToken = (primaryAccount as TrelloAccount).trelloToken || (trelloAcccount as TrelloAccount)?.trelloToken || null;
        this.googleToken = (primaryAccount as GoogleAccount).googleCredentials?.accessToken || null;
        this.outlookToken = (primaryAccount as OutlookAccount).outlookCredentials?.accessToken || null;

        this._authSrv.setPrimaryAccount(primaryAccount);
        return true;
      }),
    );
  }

  private get _clearUserLoadingState() {
    return {
      next: () => {
        this.isUserLoading = false;
        this.planywayUserLoadedSubject.next(true);
      },
      error: () => this.isUserLoading = false,
    };
  }

  startMouseflowRecording() {
    this._window._mfq = this._window._mfq || [];

    if (this._window.mouseflow) {
      this._window.mouseflow.start();
    }
  }

  stopMouseflowRecording() {
    this._window._mfq = this._window._mfq || [];
    this._window._mfq.push(['stop']);

    if (this._window.mouseflow) {
      this._window.mouseflow.stop();
    }
  }

  ngOnDestroy() {}
}
