import { Authors } from '@app/shared/models';
import { Platform } from '@app/core/services/platform/platform.model';

export enum ArticleCategory {
  All = 'All',
  UserStories = 'User stories',
  ProductUpdates = 'Product updates',
  WhatsNew = 'What\'s new',
}

export interface IBlogArticle {
  seoTitle?: string;
  author?: Authors;
  content?: string;
  date?: number;
  desc?: string;
  descPreview?: string;
  name: string;
  img?: string;
  isHidden?: boolean;
  mainPreviewImg?: string;
  backgroundColor?: string;
  backgroundImageSrc?: string;
  backgroundImageSrcSet?: string;
  backgroundImageCustomStyle?: string;
  isTop?: boolean;
  platforms: Platform[];
  readingTime?: number;
  route: string;
  type?: ArticleCategory;
  callToAction?: ICallToAction;
  customMarkdownContainerClasses?: string[];
  getTags?: Function;
}

export interface ITag {
  property?: string;
  name?: string;
  content: string;
}

export interface ICallToAction {
  heading: string,
  subheading: string,
}
