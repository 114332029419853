import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { filter, map, mergeMap } from 'rxjs/operators';

const APP_TITLE = 'Planyway Team Calendar';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _title: Title,
  ) {
  }

  init() {
    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd),
        map(() => {
          let route = this._activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        map(data => {
          if (data.title) {
            return data.title;
          }
        }))
      .subscribe((pathString) => this._title.setTitle(`${APP_TITLE} - ${pathString}`));
  }
}
