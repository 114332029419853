import { Inject, Injectable, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@env/environment';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';

import { filter, distinctUntilChanged, map, catchError } from 'rxjs/operators';
import ObjectId from 'bson-objectid';
import dayjs from 'dayjs';
import { AppInsights } from 'applicationinsights-js';
import { Subscription, BehaviorSubject, fromEvent, EMPTY } from 'rxjs';
import { v1 as uuid } from 'uuid';
import { Md5 } from 'ts-md5/dist/md5';

import { WINDOW } from '@app/core/services/window/window.service';
import { LogService } from '@app/core/services/log/log.service';
import { HttpService } from '@app/core/services/http/http.service';
import { untilDestroyed } from '@app/shared/operators';
import {
  AppType,
  BillingPeriodMap,
} from '@app/shared/models';
import {
  AMPLITUDE_API_KEY,
  APP_VERSION,
  AppInsightsInstrumentationKey,
  AMPLITUDE_API_ENDPOINT,
} from '@app/shared/constants';
import {
  PageEvents,
  MainPageEvents,
  PricingEvents,
  OrderSummaryEvents,
  SignInEvents,
  GeneralEvents,
  BlogEvents,
} from '@app/shared/models/telemetry/telemetry-events';
import {
  AI_COOKIE_SEPARATOR,
  AI_USER_COOKIE,
  AmplitudeOperations,
  AppNames,
  DISABLE_GOOGLE_ANALYTICS_FLAG,
  ITelemetryEventProperties,
  ITelemetryMetricProperties,
  TrafficChannels,
  TrafficSources,
  TrafficSourceUrl,
  UNAUTHORIZED_USER_ID_KEY,
  UtmParamsMap,
} from '@app/shared/models/telemetry/telemetry.model';
import { capitalizeFirstLetter } from '@app/shared/helpers';
import { GoogleAnalyticsOptions } from '@app/shared/models/google-analytics';
import { getRandomGroup } from '@app/shared/utils';
import { BuildTarget } from '@env';

/**
 * ВНИМАНИЕ:
 * Имена новых AB-тестов не должны совпадать с завершенными.
 * При отключении AB-теста нужно добавить его название в список завершенных в том комментарии.
 * Завершенные AB-тесты:
 * - AB Test New Main Page Header
 */
export enum ABTest {
  // NewMainPageHeader = 'AB Test New Main Page Header'
  MainPageCaseLink = 'AB Test Main Page Case Link'
}

enum PageType {
  Blog = 'blog',
  Templates = 'templates',
  Help = 'help',
}

const PageTypeNames = {
  [PageType.Blog]: 'Blog',
  [PageType.Templates]: 'Cases',
  [PageType.Help]: 'Help',
}

@Injectable({
  providedIn: 'root',
})
export class TelemetryService implements OnDestroy {
  private readonly _isBrowser: boolean;
  private _userId: string;
  private _unauthUserId: string;
  private _acquisitionDate: Date;
  private _baseProperties: any;
  private _utmProperties = {};
  private _isTelemetryAllowed = true;
  private _pageTrackingSubscription: Subscription = null;
  private _utmParseSubscription: Subscription = null;
  private _ABTests = {};

  public toggleTelemetry = new BehaviorSubject<boolean>(
    JSON.parse(this._window.localStorage.getItem('planywayTelemetryAllowed')),
  );
  public telemetryAllowed$ = this.toggleTelemetry
    .asObservable()
    .pipe(
      map(value => value === null ? true : value),
      distinctUntilChanged(),
    );

  constructor(
    private _logger: LogService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _http: HttpService,
    @Inject(WINDOW) private _window: Window,
    @Inject(PLATFORM_ID) platformId,
  ) {
    this._isBrowser = isPlatformBrowser(platformId);

    if (!this._isBrowser) {
      return;
    }

    this._baseProperties = {};
    this.initUserId({
      authUserId: null,
      planywayUserId: null,
      unAuthUserId: null
    });

    // Подписка на localStorage
    fromEvent(this._window, 'storage')
      .pipe(
        untilDestroyed(this),
        filter((e: StorageEvent) => e.key === 'planywayTelemetryAllowed'),
        map(e => JSON.parse(e.newValue)),
      )
      .subscribe(value => this.toggleTelemetry.next(value));

    this.telemetryAllowed$
      .pipe(untilDestroyed(this))
      .subscribe(async (value) => {
        this._isTelemetryAllowed = value;

        if (this._isTelemetryAllowed) {
          this.initApplicationInsights();
          this._window[DISABLE_GOOGLE_ANALYTICS_FLAG] = false;
          this._pageTrackingSubscription = this.initPagesTracking();
          this._utmParseSubscription = await this.initUtmParse();
          this.trackTrafficChannel();
        }
        else {
          this._window[DISABLE_GOOGLE_ANALYTICS_FLAG] = true;

          if (this._pageTrackingSubscription) {
            this._pageTrackingSubscription.unsubscribe();
          }

          if (this._utmParseSubscription) {
            this._utmParseSubscription.unsubscribe();
          }

          if (AppInsights.config) {
            AppInsights.config.disableTelemetry = true;
          }
        }
      });
  }

  // region - Общие публичные методы -

  get isTelemetryAllowed() {
    return this._isTelemetryAllowed;
  }

  get unauthorizedUserId() {
    return this._unauthUserId;
  }

  get referrer(): string {
    return this._window.document.referrer;
  }

  get pageName(): string {
    return this._router?.url;
  }

  setUserProperties(operation: AmplitudeOperations, userProperties) {
    this._trackAmplitudeEvent('$identify', null, {
      [operation]: userProperties,
    });
  }

  setBaseProperties(properties: { [p: string]: any }) {
    if (!this.isTelemetryAllowed) {
      return;
    }

    this._baseProperties = {
      ...this._baseProperties,
      ...properties,
    };

    this.setUserProperties(AmplitudeOperations.set, this._baseProperties);
  }

  /**
   * Инициализирует A/B-тесты
   */
  private _initABTests() {
    for (let test of Object.values(ABTest)) {
      this._ABTests[test] = getRandomGroup(`${test}${this.unauthorizedUserId}`, 2) > 0;
    }

    this.setABTests(this._ABTests);
  }

  /**
   * True, если требуется включить функционал A/B теста для пользователя, иначе - false
   * @param {ABTest} test A/B тест
   */
  isABTestEnabled(test: ABTest): boolean {
    return this._ABTests[test] ?? false;
  }

  setABTests(tests: Object): void {
    try {
      const userTests = {};

      for(let test in tests) {
        let value;

        switch (test) {
          case ABTest.MainPageCaseLink:
            value = tests[test] ? 'Get Started' : 'Learn More';
            break;

          default:
            value = tests[test] ? 'Enabled' : 'Disabled';
            break;
        }

        userTests[test] = value;
      }

      setTimeout(() => this.setBaseProperties(userTests), 0);
    }
    catch (e) {
      this._logger.error('Planyway telemetry A/B tests initialization error:');
      this._logger.error(e);
    }
  }

  trackDependency(
    id: string,
    method: string,
    absoluteUrl: string,
    totalTime: number,
    success: boolean,
    resultCode: number,
    properties?: { [name: string]: string },
  ) {
    if (!this.isTelemetryAllowed) {
      return;
    }

    properties = {
      ...properties,
    };

    AppInsights.trackDependency(id, method, absoluteUrl, '', totalTime, success, resultCode, properties);

    if (environment.target !== BuildTarget.Prod) {
      this._logger.debug('TRACK DEPENDENCY:', method, absoluteUrl);
    }
  }

  initUserId(userIdData: {unAuthUserId?: string, authUserId?: string, planywayUserId?:string}) {
    if (!this._isBrowser) {
      return;
    }

    if (!userIdData.unAuthUserId) {
      userIdData.unAuthUserId = this._window.localStorage.getItem(UNAUTHORIZED_USER_ID_KEY);

      if (!userIdData.unAuthUserId) {
        userIdData.unAuthUserId = `un_${new ObjectId()}`;
        this._window.localStorage.setItem(UNAUTHORIZED_USER_ID_KEY, userIdData.unAuthUserId);
      }
    }

    this._unauthUserId = userIdData.unAuthUserId;

    this._initABTests();

    const acqObjectId = new ObjectId(this._unauthUserId.replace(/^un_/, ''));
    this._acquisitionDate = new Date(acqObjectId.getTimestamp());

    this._baseProperties = {
      ...this._baseProperties,
      'Acquisition Date': this._acquisitionDate.toISOString(),
      'Acquisition Day': dayjs(this._acquisitionDate).format('YYYY-MM-DD'),
      'Acquisition Week': dayjs(this._acquisitionDate).format('YYYY/w'),
      'Acquisition Month': dayjs(this._acquisitionDate).format('YYYY-MM'),
      'Acquisition Year': dayjs(this._acquisitionDate).format('YYYY')
    };

    const aiUserCookie = this._getCookie(AI_USER_COOKIE);
    if (aiUserCookie) {
      const [ userId ] = aiUserCookie.split(AI_COOKIE_SEPARATOR);
      if (userId && userId !== userIdData.unAuthUserId) {
        const expire = dayjs().add(1, 'year').toDate();
        this._setCookie(AI_USER_COOKIE, `${userIdData.unAuthUserId}${AI_COOKIE_SEPARATOR}${this._acquisitionDate.toISOString()};expires=${expire.toUTCString()}`);
        return;
      }
    }

    const expire = dayjs().add(1, 'year').toDate();
    this._setCookie(AI_USER_COOKIE, `${userIdData.unAuthUserId}${AI_COOKIE_SEPARATOR}${this._acquisitionDate.toISOString()};expires=${expire.toUTCString()}`);

    if (userIdData.planywayUserId) {
      this.setUserProperties(AmplitudeOperations.set, {
        'Planyway User ID': userIdData.planywayUserId,
      })
    }

    if (userIdData.authUserId) {
      AppInsights.setAuthenticatedUserContext(userIdData.authUserId);
      this._userId = userIdData.authUserId;
    }
  }

  initApplicationInsights() {
    const aIConfig = {
      instrumentationKey: AppInsightsInstrumentationKey,
      autoTrackPageVisitTime: false,
      overrideTrackPageMetrics: true,
      overridePageViewDuration: true,
      disableExceptionTracking: true,
      disableAjaxTracking: true,
      disableDataLossAnalysis: true,
      disableTelemetry: !this.isTelemetryAllowed,
    };

    AppInsights.downloadAndSetup(aIConfig);

    AppInsights.queue.push(() => {
      AppInsights.context.addTelemetryInitializer(envelope => {
        envelope.tags = {
          ...envelope.tags,
          'ai.application.ver': APP_VERSION,
          'ai.cloud.role': AppType.Site,
          'ai.cloud.roleVer': APP_VERSION,
          'ai.cloud.roleInstance': APP_VERSION,
        };

        const telemetryItem = envelope.data.baseData;
        switch (envelope.name) {
          case Microsoft.ApplicationInsights.Telemetry.PageView.envelopeType:
            telemetryItem.url = this._removeSecureData(telemetryItem.url);
            telemetryItem.name = this._removeSecureData(telemetryItem.name.split('?')[0]);
            break;

          case Microsoft.ApplicationInsights.Telemetry.RemoteDependencyData.envelopeType:
            telemetryItem.name = this._removeSecureData(telemetryItem.name.split('?')[0]);
            break;

          case Microsoft.ApplicationInsights.Telemetry.Exception.envelopeType:
            for (const exception of telemetryItem.exceptions) {
              exception.aiDataContract = undefined;
            }
            break;
        }
      });
    });
  }

  initUtmParse() {
    return this._activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(params => {
        for (const paramKey in params) {
          if (params.hasOwnProperty(paramKey) && UtmParamsMap[paramKey]) {
            this._utmProperties[UtmParamsMap[paramKey]] = params[paramKey];
          }
        }
      });
  }

  initPagesTracking() {
    return this._router.events
      .pipe(
        filter(event => (
          event instanceof NavigationStart ||
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        )),
      )
      .subscribe((event: RouterEvent) => {
        const pageName = event.url.split('?')[0].split('#')[0];

        switch (true) {
          case event instanceof NavigationStart:
            AppInsights.startTrackPage(pageName);
            break;

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError:
            const url = event instanceof NavigationEnd ? event.urlAfterRedirects : event.url;

            // Фикс для отслеживания первой навигации,
            // так как ангуляр инициализирует сервисы после первого NavigationStart
            if (event.id === 1) {
              AppInsights.trackPageView(pageName, url);
            }
            else {
              AppInsights.stopTrackPage(pageName, url);
            }

            const pageType = capitalizeFirstLetter(
              url.split('/')
                .filter(segment => segment)[0]
            );

            this._trackAmplitudeEvent(PageEvents.PageView, {
              ...this._utmProperties,
              'Page Name': pageName,
              'Page Type': PageTypeNames[PageType[pageType]],
              'Url': this._removeSecureData(url),
            });
            break;
        }
      });
  }

  trackGAEvent(options: GoogleAnalyticsOptions) {
    const { category, action } = options;
    this._window.ga('send', 'event', category, action);
  }

  trackTrafficChannel() {
    if (!this.isTelemetryAllowed) {
      return;
    }

    const referrerName = this.referrer.replace(/^(https?:\/\/(\w{0,3}\.){0,}){1}|[\/].*$/gi, '');
    const referrerUrl = this.referrer.replace(/^(https?:\/\/(\w\.){0,})(.*?)((?=\/){1}|$)/i, '');
    const userProperties = {
      Source: TrafficSources.direct,
      SourceUrl: referrerUrl as TrafficSources || TrafficSources.none,
      Channel: TrafficChannels.direct,
    };

    switch (true) {
      case this._utmProperties[UtmParamsMap.utm_medium]?.toUpperCase() === TrafficChannels.cpc:
        userProperties.Source = capitalizeFirstLetter(this._utmProperties[UtmParamsMap.utm_source]) as TrafficSources;
        userProperties.SourceUrl = TrafficSources.none;
        userProperties.Channel = TrafficChannels[this._utmProperties[UtmParamsMap.utm_medium]];
        break;

      case !!referrerName: {
        switch (referrerName) {
          case TrafficSourceUrl.google:
          case TrafficSourceUrl.yandex:
          case TrafficSourceUrl.bing:
            userProperties.Source = TrafficSources[referrerName];
            userProperties.Channel = TrafficChannels.organic;
            break;

          case TrafficSourceUrl.trello:
          case TrafficSourceUrl.chromeStore:
            userProperties.Source = TrafficSources[referrerName];
            userProperties.Channel = TrafficChannels.referral;
            break;

          case TrafficSourceUrl.planyway:
            userProperties.SourceUrl = TrafficSources.none;
            break;

          default:
            userProperties.Source = capitalizeFirstLetter(referrerName) as TrafficSources;
            userProperties.Channel = TrafficChannels.referral;
            break;
        }
        break;
      }
      case !!this._utmProperties[UtmParamsMap.utm_source]:
        userProperties.Source = capitalizeFirstLetter(this._utmProperties[UtmParamsMap.utm_source]) as TrafficSources;

        if (this._utmProperties[UtmParamsMap.utm_medium]) {
          userProperties.Channel = TrafficChannels[this._utmProperties[UtmParamsMap.utm_medium]]
            || TrafficChannels.direct;
        }
        break;
    }

    this.setUserProperties(AmplitudeOperations.setOnce, userProperties);
  }

  /**
   * Отправляет событие телеметрии в Amplitude и AppInsights
   *
   * @param {string} eventName имя события
   * @param {ITelemetryEventProperties} eventProperties опциональные свойства события
   * @param {ITelemetryMetricProperties} metricsProperties опциональные свойства метрик
   *
   * @returns {void}
   * @memberof TelemetryService
   */
  trackEvent(
    eventName: string,
    eventProperties: ITelemetryEventProperties = {},
    metricsProperties: ITelemetryMetricProperties = {},
  ): void {
    if (!this.isTelemetryAllowed) {
      return;
    }

    switch (eventName) {
      case MainPageEvents.FooterAppBadgeClicked:
        eventProperties = {
          'Mobile App Platform': AppNames[eventProperties.appType],
        };
      break;

      case SignInEvents.SignInClicked:
        if (eventProperties.hasOwnProperty('appType')) {
          eventProperties['Platform'] = AppNames[eventProperties.appType];
          delete eventProperties['appType'];
        }

        eventProperties['Page Name'] = this.pageName.split('#')[0];
      break;

      case OrderSummaryEvents.OrderSummaryPaymentPeriodChanged:
      case OrderSummaryEvents.OrderSummaryCancelButtonClicked:
      case OrderSummaryEvents.OrderSummaryConfirmButtonClicked:
      case PricingEvents.PricingPaymentPeriodChanged:
      case PricingEvents.PricingPurchaseButtonClicked:
        eventProperties['Payment Period'] = BillingPeriodMap[eventProperties['Payment Period']];
      break;

      case GeneralEvents.ContactUsClicked:
      case MainPageEvents.MainPageClicked:
      case BlogEvents.InnerBannerClick:
      case BlogEvents.AudioClick:
      case BlogEvents.LowerBannerClick:
        eventProperties['Page Name'] = this.pageName;
        break;
    }

    this._trackAmplitudeEvent(eventName, {
      ...this._utmProperties,
      ...eventProperties,
    });

    AppInsights.trackEvent(eventName, {
      ...this._utmProperties,
      ...eventProperties,
      ...this._baseProperties,
      [`Event Source Url`]: this._removeSecureData(this._router.url.split('?')[0]),
    }, metricsProperties);

    if (environment.target !== BuildTarget.Prod) {
      this._logger.debug('TRACK EVENT:', eventName, {
        ...this._utmProperties,
        ...eventProperties,
      });
    }
  }

  // endregion

  // region - Приватные методы Amplitude -

  /**
  * Отправляет событие в Amplitude
  *
  * @param {string} eventType тип события
  * @param {ITelemetryEventProperties} eventProperties опциональные свойства события
  * @param {ITelemetryEventProperties} userProperties опциональные свойства пользователя
  *
  * @returns {TelemetryService}
  * @memberof TelemetryService
  *
  */
  private _trackAmplitudeEvent(
    eventType: string,
    eventProperties: ITelemetryEventProperties = {},
    userProperties: ITelemetryEventProperties = {},
  ): TelemetryService {
    if (!this._userId) {
      // Не шлем событие в Amplitude для незалогиненных пользователей
      return;
    }

    const eventUuid = uuid();
    const uploadTime = new Date();
    const requestData = new URLSearchParams();

    const eventData = JSON.stringify([
      {
        device_id: this.unauthorizedUserId,
        user_id: this._userId,
        uuid: eventUuid,
        insert_id: eventUuid,
        timestamp: Date.now(),
        event_type: eventType,
        event_properties: eventProperties,
        user_properties: userProperties,
        api_properties: {
            tracking_options: {
                ip_address: false,
            },
        },
      },
    ]);

    requestData.set('client', AMPLITUDE_API_KEY);
    requestData.set('e', eventData);
    requestData.set('v', '2');
    requestData.set('upload_time', uploadTime.getTime().toString());
    requestData.set('checksum', Md5.hashStr(2 + AMPLITUDE_API_KEY + eventData + uploadTime.getTime()).toString());

    this._http.post<any>(AMPLITUDE_API_ENDPOINT, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: requestData.toString(),
    }).pipe(catchError(() => EMPTY)).toPromise();

    return this;
  }

  // endregion

  // region - Приватные общие методы -

  private _getCookie(name) {
    const document = this._window.document;

    let value = '';
    if (name && name.length) {
      const cookieName = name + '=';
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        cookie = cookie.trim();
        if (cookie && cookie.indexOf(cookieName) === 0) {
          value = cookie.substring(cookieName.length, cookies[i].length);
          break;
        }
      }
    }

    return value;
  }

  private _setCookie(name, value, domain?) {
    const document = this._window.document;

    let domainAttrib = '';
    let secureAttrib = '';

    if (domain) {
      domainAttrib = ';domain=' + domain;
    }

    if (document.location && document.location.protocol === 'https:') {
      secureAttrib = ';secure';
    }

    document.cookie = name + '=' + value + domainAttrib + ';path=/' + secureAttrib;
  }

  private _removeSecureData(data: string) {
    return data
      .replace(/[0-9a-f]{24}/g, '{id}')
      .replace(/[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}/g, '{guid}');
  }

  // endregion

  ngOnDestroy() {}
}
