import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeWhile } from 'rxjs/operators';
import { environment } from '@env/environment';
import {
  BACKEND_URL, GOOGLE_CLIENT_ID, PLANYWAY_TOKEN_HEADER_NAME, STANDALONE_URL, TRELLO_API_KEY, TRELLO_APP_NAME,
} from '@app/shared/constants';
import { BackendResponse, NotificationType, RequestMethods, TelemetryEvents, } from '@app/shared/models';
import {
  AUTH_TOKEN_HEADER_NAME, AUTH_TOKEN_USE_HEADER_NAME, AuthResponseData, FailureCode,
  PLANYWAY_AUTH_TOKEN_KEY, PLANYWAY_AUTH_URL, PLANYWAY_TOKEN_KEY, SESSION_TOKEN_HEADER_NAME, TRELLO_AUTH_BASE_URL,
  TRELLO_AUTH_ENDPOINT,
  GOOGLE_AUTH_BASE_URL,
  GoogleScope,
  OutlookScope,
} from '@app/core/services/auth/auth.model';
import { WINDOW } from '@app/core/services/window/window.service';
import { HttpService } from '@app/core/services/http/http.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { TelemetryService } from '@app/core/services/telemetry/telemetry.service';
import { BusyService } from '@app/core/services/busy/busy.service';
import { AccountType } from '@planyway-common/common';
import { Account, TrelloAccount } from '../account/account.model';
import { BuildTarget } from '@env';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private planywayTokenSubject = new BehaviorSubject<string>(
    this._window.localStorage.getItem(PLANYWAY_TOKEN_KEY),
  );
  readonly planywayToken$ = this.planywayTokenSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private planywayAuthTokenSubject = new BehaviorSubject<string>(
    this._window.localStorage.getItem(PLANYWAY_AUTH_TOKEN_KEY),
  );
  readonly planywayAuthToken$ = this.planywayAuthTokenSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private planywayUserIdSubject = new ReplaySubject<string>(1);
  readonly planywayUserId$ = this.planywayUserIdSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private sessionToken: string;
  isAuthenticated = false;
  isAuthenticatedSubject = new BehaviorSubject<boolean | null>(null);

  primaryAccount: Account;
  trelloAcccount: Account;

  isTrelloPrimaryAccount: boolean = false;
  isTrelloPrimaryAccountSubject = new BehaviorSubject<boolean | null>(null);

  isTrelloAccountAuthenticated$: Observable<boolean>;

  constructor(
    @Inject(WINDOW) private _window,
    private _http: HttpService,
    private _notificationSrv: NotificationService,
    private _telemetrySrv: TelemetryService,
    private _route: ActivatedRoute,
    private _location: Location,
    private _busySrv: BusyService,
  ) {
    this.isTrelloAccountAuthenticated$ = combineLatest([
      this.isAuthenticatedSubject,
      this.isTrelloPrimaryAccountSubject
    ]).pipe(
      map(([isAuthenticated, isTrello]) => {
        return !!isAuthenticated && !!isTrello;
      }),
    );
  }

  /**
   * Проверяет, наличие токена в хэше текущего URL, если есть делаем Sign In и редирект в Standalone
   *
   * @returns {Observable<boolean>}
   */
  checkSignInByRedirectUrl(): Observable<boolean> {
    const tokenMatcher = /[&#]?token=(\w*)/;
    const errorMatcher = /[&#]?error=(\w.*)/;

    return this._route.fragment.pipe(
      takeWhile(fragment => !fragment, true),
      switchMap(fragment => {
        const token = tokenMatcher.exec(fragment)?.[1];
        const error = errorMatcher.exec(fragment)?.[1];

        if (fragment) {
          this._location.replaceState(this._location.path());
        }

        if (error) {
          this._notificationSrv.show(NotificationType.Error, error, 5000);
        }

        if (token) {
          this._busySrv.isAppBusy = true;

          return this.signInByProviderToken(token);
        }

        return of(false);
      }),
      switchMap(userId => {
        if (!!userId) {
          this._window.location.replace(STANDALONE_URL);
        }

        return of(!!userId);
      }),
    );
  }

  /**
   * Проверяет, вошел ли пользователь в Planyway
   *
   * @returns {Observable<boolean>}
   */
  checkSignedIn(): Observable<boolean> {
    return this.authorizedRequest<BackendResponse<AuthResponseData>>(
      RequestMethods.Get,
      `${BACKEND_URL}/${PLANYWAY_AUTH_URL}`,
      {},
      { usePlanywayAuth: true },
    ).pipe(
      map(({ body }) => {
        if (!body.success) {
          switch (body.code) {
            case FailureCode.AuthUnauthorized:
            case FailureCode.AuthInvalidToken:
            case FailureCode.AuthTokenExpired:
            case FailureCode.AuthUserNotFound:
              // Уведомление об ошибке не требуется
              break;

            default:
              this._notificationSrv.show(
                NotificationType.Error,
                `Planyway sign in check failed. ${body.message}`,
              );
              break;
          }

          this.isAuthenticated = false;
          this.isAuthenticatedSubject.next(false);

          return false;
        }

        this.planywayUserIdSubject.next(body.data.userId);

        this.isAuthenticated = true;
        this.isAuthenticatedSubject.next(true);

        return true;
      }),
    );
  }

  setPrimaryAccount(account: Account) {
    this.primaryAccount = account;
    this.isTrelloPrimaryAccount = this.primaryAccount.accountType === AccountType.Trello;
    this.isTrelloPrimaryAccountSubject.next(this.primaryAccount.accountType === AccountType.Trello);
  }

  setTrelloAccount(account: Account) {
    this.trelloAcccount = account;
  }

  /**
   * Возвращает адрес аутентификации Trello с параметрами для попапа
   *
   * @return {string} адрес аутентификации
   */
  private async _getAuthUrl(providerType: AccountType = AccountType.Trello): Promise<string> {
    const returnUrlExec = /^[a-z]+:\/\/[^/]*/.exec(this._window.location.toString());
    switch (providerType) {
      case AccountType.Trello: {

        const queryParams = new URLSearchParams();
        queryParams.set('name', TRELLO_APP_NAME);
        queryParams.set('return_url', returnUrlExec[0] || null);
        queryParams.set('callback_method', 'postMessage');
        queryParams.set('expiration', 'never');
        queryParams.set('scope', 'read,write,account');
        queryParams.set('response_type', 'token');
        queryParams.set('key', TRELLO_API_KEY);
        const paramUrlString = queryParams.toString();

        return `${TRELLO_AUTH_BASE_URL}/authorize?${paramUrlString}`;
      }

      case AccountType.Google: {
        const redirectUri = `${returnUrlExec[0]}/exchange.html`;

        const queryParams = new URLSearchParams();
        queryParams.set('client_id', GOOGLE_CLIENT_ID);
        queryParams.set('redirect_uri', redirectUri);
        queryParams.set('response_type', 'code');
        queryParams.set('access_type', 'offline');
        queryParams.set('scope', `${Object.values(GoogleScope).join(' ')}`);
        queryParams.set('prompt', 'consent');

        const paramUrlString = queryParams.toString();

        return `${GOOGLE_AUTH_BASE_URL}/authorize?${paramUrlString}`;
      }

      case AccountType.Outlook: {
        const redirectUri = `${returnUrlExec[0]}/exchange.html`;
        const url = `${BACKEND_URL}/planyway/auth`;
        const data = {
          body: {
            action: 'getAuthCodeUrl',
            data: {
              accountType: AccountType.Outlook,
              outlookRedirectUrl: redirectUri,
              outlookScopes: Array.from(Object.values(OutlookScope))
            }
          },
          usePlanywayAuth: true
        };

        const response = await firstValueFrom(this._http.post<any>(url, data));

        if (!response['success']) {
          let error = new Error(response['message']);
          error.message = response['details'];

          throw error;
        }

        return response['data']['authCodeUrl'] as string;
      }
    }
  }

  /**
   * Асинхронно открывает всплывающее окно аутентификации Trello/Google/Outlook
   *
   * @returns {Promise<string>} токен стороннего сервиса
   *
   * @throws {Error} ошибка аутентификации
   */
  openAuthPopupAsync(providerType: AccountType = AccountType.Trello): Promise<string> {
    return new Promise(async(resolve, reject) => {
      try {
        const popupWidth = 620;
        const popupHeight = 720;
        const popupLeft =
          this._window.screenX + (this._window.innerWidth - popupWidth) / 2;
        const popupTop =
          this._window.screenY + (this._window.innerHeight - popupHeight) / 2;

        const authUrl = await this._getAuthUrl(providerType);
        const authPopup = this._window.open(
          authUrl,
          'planywayAuthWindow',
          `width=${popupWidth},height=${popupHeight},left=${popupLeft},top=${popupTop}`,
        );

        if (authPopup) {
          let thirdPartyToken = null;
          const that = this;
          const timer = setInterval(function () {
            if (authPopup.closed) {
              // Пустой токен - признак, что окно закрыл сам пользователь
              if (thirdPartyToken === null) {
                that._telemetrySrv.trackEvent(TelemetryEvents.AuthEvents.AuthRejected);

                reject(null);

                clearInterval(timer);
              }

              resolve(thirdPartyToken);

              clearInterval(timer);
            }
          }, 200);

          const onMessageReceived = args => {
            if (
              args.origin !== TRELLO_AUTH_ENDPOINT &&
              args.origin !== window.location.origin ||
              args.source !== authPopup
            ) {
              return;
            }

            this._window.removeEventListener('message', onMessageReceived);
            const responseToken = args.data.data ?? args.data;

            if (/^\S+$/.test(responseToken)) {
              thirdPartyToken = responseToken;
              authPopup.close();
            } else {
              clearInterval(timer);
              authPopup.close();

              this._telemetrySrv.trackEvent(TelemetryEvents.AuthEvents.AuthRejected);

              if(args.origin === TRELLO_AUTH_ENDPOINT && args.data) {
                reject(new Error(`Invalid token message data: ${args.data}`));
              }

              if(args.origin === window.location.origin && !args.data.data) {
                reject(new Error(`Invalid token message data: ${args.data.error}`));
              }
            }
          };

          this._window.addEventListener('message', onMessageReceived);
        } else {
          reject(new Error('Cannot open auth popup!'));
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  /**
   * Возвращает адрес аутентификации Trello с параметрами для текущего окна
   *
   * @return {string} адрес аутентификации
   */
  private _getAuthByFragmentUrl(): string {
    const returnUrlExec = /^[a-z]+:\/\/[^/]*/.exec(
      this._window.location.toString(),
    );
    const paramUrlString = new HttpParams()
      .set('name', TRELLO_APP_NAME)
      .set('return_url', returnUrlExec[0] || null)
      .set('callback_method', 'fragment')
      .set('expiration', 'never')
      .set('scope', 'read,write,account')
      .set('response_type', 'token')
      .set('key', TRELLO_API_KEY)
      .toString();

    return `${TRELLO_AUTH_BASE_URL}/authorize?${paramUrlString}`;
  }

  /**
   * Открывает страницу аутентификации Trello в текущей вкладке
   *
   * @returns {string}
   *
   * @throws {Error} ошибка изменения url
   */
  openTrelloAuthPage(): string {
    return this._window.location = this._getAuthByFragmentUrl();
  }

  /**
   * Производит авторизацию planyway по токену Trello/Google/Outlook
   *
   * @param {string=} token токен
   * @param {AccountType} accountType тип аккаунта
   *
   * @returns {Observable<string>} идентфикатор пользователя, осуществившего вход или null, если вход не был осуществлён
   */
  signInByProviderToken(token: string | undefined, accountType: AccountType = AccountType.Trello): Observable<string> {
    if (!token) {
      this._telemetrySrv.trackEvent(TelemetryEvents.AuthEvents.AuthRejected);
      this.isAuthenticated = false;
      this.isAuthenticatedSubject.next(false);
      return of(null);
    }

    this._telemetrySrv.trackEvent(TelemetryEvents.AuthEvents.AuthConfirmed);
    const returnUrlExec = /^[a-z]+:\/\/[^/]*/.exec(this._window.location.toString());
    const redirectUrl = `${returnUrlExec[0]}/exchange.html`;
    const authData = {};

    switch(accountType) {
      case AccountType.Trello: {
        authData['trelloToken'] = token;
        break;
      }
      case AccountType.Google: {
        authData['googleRedirectUrl'] = redirectUrl;
        authData['googleAuthCode'] = token;
        break;
      }
      case AccountType.Outlook: {
        authData['outlookRedirectUrl'] = redirectUrl;
        authData['outlookAuthCode'] = token;
        authData['outlookScopes'] = Array.from(Object.values(OutlookScope));
        break;
      }
    }

    authData['accountType'] = accountType;

    return this.authorizedRequest<BackendResponse<AuthResponseData>>(
      RequestMethods.Post,
      `${BACKEND_URL}/${PLANYWAY_AUTH_URL}`,
      {
        body: {
          action: 'signIn',
          data: authData,
        },
      },
      {
        usePlanywayAuth: true,
      },
    ).pipe(
      map(({ body }) => {
        if (!body.success) {
          this._notificationSrv.show(
            NotificationType.Error,
            `Planyway sign in failed. ${body.message}`,
          );

          this.isAuthenticated = false;
          this.isAuthenticatedSubject.next(false);

          return;
        }

        this.planywayUserIdSubject.next(body.data.userId);

        this.isAuthenticated = true;
        this.isAuthenticatedSubject.next(true);

        return body.data.userId;
      }),
    );
  }

  signOut() {
    this.planywayUserIdSubject.next(null);
    this.isAuthenticated = false;
    this.isAuthenticatedSubject.next(false);
    return of(true);
  }

  /**
   * Токен Trello
   */
  get trelloToken() {
    return (this.primaryAccount as TrelloAccount)?.trelloToken;
  }

  /**
   * Токен авторизации Planyway
   */
  get planywayAuthToken() {
    return this.planywayAuthTokenSubject.getValue();
  }

  /**
   * Устанавливает токен авторизации Planyway
   *
   * @param {string} value
   */
  set planywayAuthToken(value: string) {
    if (value === null || value === undefined) {
      this._window.localStorage.removeItem(PLANYWAY_AUTH_TOKEN_KEY);
    } else {
      this._window.localStorage.setItem(PLANYWAY_AUTH_TOKEN_KEY, value);
    }
    this.planywayAuthTokenSubject.next(value);
  }

  /**
   * Токен Planyway
   */
  get planywayToken() {
    return this.planywayTokenSubject.getValue();
  }

  /**
   * Устанавливает токен planyway
   *
   * @param {string} value
   */
  set planywayToken(value: string) {
    if (value === null || value === undefined) {
      this._window.localStorage.removeItem(PLANYWAY_TOKEN_KEY);
    } else {
      this._window.localStorage.setItem(PLANYWAY_TOKEN_KEY, value);
    }

    this.planywayTokenSubject.next(value);
  }

  /**
   * Выполняет запрос к бекенду Planyway используя данные авторизации пользователя
   *
   * @param method
   * @param url
   * @param config
   * @param options
   */
  authorizedRequest<T>(
    method: RequestMethods,
    url: string,
    config: {
      headers?: object;
      withCredentials?: boolean;
      body?: object;
      query?: object;
      observe?: string;
    } = {},
    options: {
      usePlanywayAuth?: boolean;
      useTrelloAuth?: boolean;
    },
  ): Observable<T> {
    config = {
      headers: {},
      ...config,
      observe: 'response',
    };

    if (this.trelloToken) {
      config.headers['Authorization'] = `Bearer ${this.trelloToken}`;
    }

    if (options.usePlanywayAuth) {
      if (this.sessionToken) {
        config.headers[SESSION_TOKEN_HEADER_NAME] = this.sessionToken;
      }

      if (environment.target !== BuildTarget.Prod) {
        config.headers[AUTH_TOKEN_USE_HEADER_NAME] = 'true';

        if (this.planywayAuthToken) {
          config.headers[AUTH_TOKEN_HEADER_NAME] = this.planywayAuthToken;
        }
      } else {
        config.withCredentials = true;
      }
    } else if (options.useTrelloAuth) {
      if (this.trelloToken && this.planywayToken) {
        config.headers[PLANYWAY_TOKEN_HEADER_NAME] = this.planywayToken;
      }
    }

    let req;
    switch (method) {
      case RequestMethods.Get:
        req = this._http.get<T>(url, config);
        break;

      case RequestMethods.Post:
        req = this._http.post<T>(url, config);
        break;

      case RequestMethods.Put:
        req = this._http.put<T>(url, config);
        break;
    }

    return req.pipe(
      map((res: Response) => {
        // Сохраняем данные аутентификации
        if (options.usePlanywayAuth) {
          if (res.headers.has(SESSION_TOKEN_HEADER_NAME)) {
            this.sessionToken = res.headers.get(SESSION_TOKEN_HEADER_NAME);
          }

          if (environment.target !== BuildTarget.Prod) {
            try {
              if (res.headers.has(AUTH_TOKEN_HEADER_NAME)) {
                this.planywayAuthToken = res.headers.get(
                  AUTH_TOKEN_HEADER_NAME,
                );
              } else if (
                method === RequestMethods.Post &&
                url.endsWith(PLANYWAY_AUTH_URL)
              ) {
                this.planywayAuthToken = null;
              }
            } catch {
              // Игнорируем исключение
            }
          }
        } else if (options.useTrelloAuth) {
          if (this.trelloToken) {
            if (res.headers.has(PLANYWAY_TOKEN_HEADER_NAME)) {
              try {
                this.planywayToken = res.headers.get(
                  PLANYWAY_TOKEN_HEADER_NAME,
                );
              } catch {
                // Игнорируем исключение
              }
            }
          }
        }

        return res;
      }),
    );
  }
}
