import { Inject, Injectable, isDevMode } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { WINDOW } from '@app/core/services/window/window.service';
import { ScriptService } from '@app/core/services/script/script.service';
import { PADDLE_VENDOR_ID } from '@app/shared/constants';
import { LogService } from '@app/core/services/log/log.service';
import { TelemetryService } from '@app/core/services/telemetry/telemetry.service';
import { CheckoutEvent, PaddleCheckoutEvents, PaddleCheckoutMode, TelemetryEvents } from "@app/shared/models";

const PADDLE_SRC = 'https://cdn.paddle.com/paddle/paddle.js';

enum PaddlePaymentMethods {
  'SPREEDLY_CARD' = 'Card',
  'PAYPAL' = 'PayPal'
}

@Injectable({
  providedIn: 'root'
})
export class PaddleService {
  private _checkoutEventsSubject = new Subject<CheckoutEvent>();
  checkoutEvents$ = this._checkoutEventsSubject.asObservable();

  private _paddle: {
    Environment: any;
    Setup: Function,
    Checkout: any
  };
  private _isInitialised: boolean;

  constructor(
    @Inject(WINDOW) private _window: Window | any,
    private _scriptSrv: ScriptService,
    private _logSrv: LogService,
    private _telemetrySrv: TelemetryService,
  ) {
  }

  Checkout(override: string, mode: PaddleCheckoutMode) {
    if (this._isInitialised) {
      return this._openCheckout(override, mode);
    }

    return this.init().pipe(
      switchMap(result => {
        if (result) {
          return this._openCheckout(override, mode);
        }
        else {
          return of({
            success: false,
            message: 'Can not load Paddle Checkout'
          });
        }
      })
    );
  }

  _openCheckout(override, mode: PaddleCheckoutMode) {
    let additionalConfigOptions: {[key: string]: string | number};

    switch (mode) {
      case PaddleCheckoutMode.Inline:
        additionalConfigOptions = {
          frameTarget: 'checkout__widget',
          frameInitialHeight: 432,
          frameStyle: 'width:100%; min-height: 100%; background-color: white; border: none;',
        }
    }

    return new Observable<{ success: boolean }>(observer => {
      this._paddle.Checkout.open({
        method: mode,
        override: override,
        disableLogout: true,
        locale: 'en',
        ...additionalConfigOptions,
        successCallback: () => {
          observer.next({ success: true });
          observer.complete();
        },
        closeCallback: () => {
          observer.next({ success: false });
          observer.complete();
        },
      });
    });
  }

  getPaymentMethod(event) {
    return PaddlePaymentMethods[event.eventData.payment_method];
  }

  init(): Observable<boolean> {
    if (this._isInitialised) {
      return of(true);
    }

    this.checkoutEvents$
      .subscribe(checkoutEvent => {
        switch (checkoutEvent.event) {
          case PaddleCheckoutEvents.PaymentMethodSelected:
            this._telemetrySrv.trackEvent(
              TelemetryEvents.CheckoutEvents.CheckoutPaymentMethodSelected,
              { 'Payment Method': this.getPaymentMethod(checkoutEvent) }
            );
            break;

          case PaddleCheckoutEvents.Complete:
            // instead Checkout Subscribe Now Clicked
            this._telemetrySrv.trackEvent(TelemetryEvents.CheckoutEvents.CheckoutComplete);
            break;

          case PaddleCheckoutEvents.Close:
            this._telemetrySrv.trackEvent(TelemetryEvents.CheckoutEvents.CheckoutClosed);
            break;

          default:
            break;
        }
      });

    return this._scriptSrv.load(PADDLE_SRC).pipe(
      map(loaded => {
        if (loaded) {
          this._paddle = this._window.Paddle;

          if (isDevMode()) {
            this._paddle.Environment.set('sandbox');
          }

          this._paddle.Setup({
            vendor: PADDLE_VENDOR_ID,
            eventCallback: (checkoutEvent: CheckoutEvent) => {
              this._checkoutEventsSubject.next(checkoutEvent);
            }
          });
        }

        this._isInitialised = loaded;

        return loaded;
      }),
      catchError(err => {
        this._logSrv.error(`Failed to initialize Paddle SDK:`, err.message);
        this._logSrv.error(err);
        return of(false);
      })
    );
  }
}


