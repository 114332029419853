import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProgressMode } from '@app/shared/models/progress.interface';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProgressService implements OnDestroy {
  progressMode$: BehaviorSubject<ProgressMode> = new BehaviorSubject<ProgressMode>('determinate');
  progressValue$: BehaviorSubject<number> = new BehaviorSubject<number>(5);

  private _routerSubscription: Subscription;

  constructor(private _router: Router) {
  }

  setProgressMode(value: ProgressMode) {
    this.progressMode$.next(value);
  }

  setProgressValue(value) {
    this.progressValue$.next(value);
  }

  init() {
    this._routerSubscription = this._router.events.pipe(delay(150)).subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart:
          this.setProgressValue(10);
          this.setProgressMode('determinate');
          break;

        case event instanceof RouteConfigLoadStart:
          this.setProgressValue(40);
          break;

        case event instanceof RouteConfigLoadEnd:
          this.setProgressValue(60);
          break;

        case event instanceof NavigationEnd:
        case event instanceof NavigationError:
        case event instanceof NavigationCancel:
          this.setProgressMode(null);
          this.setProgressValue(100);
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this._routerSubscription.unsubscribe();
  }
}
