import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor() {
  }

  // noinspection JSUnusedLocalSymbols
  debug(message?: any, ...optionalParams: any[]): void {
  }

  // noinspection JSUnusedLocalSymbols
  info(message?: any, ...optionalParams: any[]): void {
  }

  // noinspection JSUnusedLocalSymbols
  log(message?: any, ...optionalParams: any[]): void {
  }

  // noinspection JSUnusedLocalSymbols
  warn(message?: any, ...optionalParams: any[]): void {
  }

  // noinspection JSUnusedLocalSymbols
  error(message?: any, ...optionalParams: any[]): void {
  }
}
