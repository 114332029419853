import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AccountService } from '@app/core/services/account/account.service';
import { AuthService } from '@app/core/services/auth/auth.service';
import { PaddleService } from '@app/core/services/paddle/paddle.service';
import { CloudPaymentsService } from '@app/core/services/cloudpayments/cloudpayments.service';
import { BACKEND_URL, PLANYWAY_TOKEN_HEADER_NAME } from '@app/shared/constants';
import {
  BackendResponse,
  IPaymentData,
  PaddleCheckoutMode,
  PaymentPlatform,
  RequestMethods,
} from '@app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  private _paymentPlatform : PaymentPlatform;

  constructor(
    private _accountSrv: AccountService,
    private _authSrv: AuthService,
    private _cloudPaymentSrv: CloudPaymentsService,
    private _paddleSrv: PaddleService,
  ) {}

  /**
   * Инициализирует сервис для оплаты подписки
   *
   * @param paymentPlatform
   */
  init(paymentPlatform: PaymentPlatform) {
    this._paymentPlatform = paymentPlatform;

    switch (paymentPlatform) {
      case PaymentPlatform.Paddle:
        return this._paddleSrv.init();

      case PaymentPlatform.CloudPayments:
        return this._cloudPaymentSrv.init();

      default:
        break;
    }

    return of(null);
  }

  /**
   * Выполняет оплату с предварительной подготовкой в Prepare Payment Service
   *
   * @param paymentData
   * @param checkoutMode
   */
  purchase(
    paymentData: {
      data: IPaymentData;
      token: string;
      planywayToken: string;
      userId: string;
    },
    checkoutMode?: PaddleCheckoutMode,
  ): Observable<any> {
    if (!this._paymentPlatform) {
      return of(null);
    }

    return this._preparePayment(paymentData).pipe(
      switchMap(prepareResponse => {
        if (prepareResponse.success) {
          return this._checkout(prepareResponse.data, checkoutMode);
        }

        return of(prepareResponse);
      })
    );
  }

  /**
   * Запрашивает данные для оплаты
   *
   * @param paymentData
   */
  private _preparePayment(paymentData: {
    data: IPaymentData;
    token: string;
    planywayToken: string;
    userId: string;
  }) {
    const { data, token, userId, planywayToken } = paymentData;

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (token && planywayToken) {
      headers[PLANYWAY_TOKEN_HEADER_NAME] = planywayToken;
    }

    return this._authSrv
      .authorizedRequest<BackendResponse<string>>(
        RequestMethods.Post,
        `${BACKEND_URL}/planyway/${userId}/subscription`,
        {
          body: {
            action: 'checkout' + this._paymentPlatform,
            data,
          },
        },
        {
          usePlanywayAuth: true,
        },
      )
      .pipe(
        map((res: any) => {
          if (token) {
            if (res.headers.has(PLANYWAY_TOKEN_HEADER_NAME)) {
              try {
                this._accountSrv.planywayToken = res.headers.get(
                  PLANYWAY_TOKEN_HEADER_NAME,
                );
              } catch {
                // Игнорируем исключение
              }
            }
          }

          return res.body;
        }),
      );
  }

  /**
   * Открывает окно для оплаты
   *
   * @param {string} response
   * @param {'inline' | 'overlay'} mode
   *
   * @return Observable<boolean> True, если оплата прошла, иначе - False
   */
  private _checkout(
    response: string,
    mode: PaddleCheckoutMode
  ): Observable<any> {
    switch (this._paymentPlatform) {
      case PaymentPlatform.Paddle:
        return this._paddleSrv.Checkout(response, mode);

      case PaymentPlatform.CloudPayments:
        return this._cloudPaymentSrv.Checkout(response);

      default:
        break;
    }

    return null;
  }
}
