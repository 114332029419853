import { AppType } from '../common';

export interface ITelemetryEventProperties {
  [name: string]: string | number;
}

export interface ITelemetryMetricProperties {
  [p: string]: number;
}

export const UNAUTHORIZED_USER_ID_KEY = 'planywayUnauthorizedId';

export const AI_USER_COOKIE = 'ai_user';
export const AI_COOKIE_SEPARATOR = '|';

export const UtmParamsMap = {
  utm_source: 'Utm Source',
  utm_campaign: 'Utm Campaign',
  utm_medium: 'Utm Medium',
  utm_content: 'Utm Content',
  utm_term: 'Utm Term',
};

export const AppNames = {
  [AppType.Chrome]: 'Chrome Extension',
  [AppType.Safari]: 'Safari Extension',
  [AppType.PowerUp]: 'PowerUp',
  [AppType.Standalone]: 'Standalone',
  [AppType.Teams]: 'Teams',
  [AppType.Site]: 'Site',
  [AppType.Jira]: 'Jira Site',
  [AppType.Android]: 'Android App',
  [AppType.iOS]: 'iOS App',
  [AppType.Trello]: 'Trello Site',
};

export enum AmplitudeOperations {
  app = '$add',
  append = '$append',
  clearAll = '$clearAll',
  prepend = '$prepend',
  set = '$set',
  setOnce = '$setOnce',
  unset = '$unset',
}

export enum TrafficChannels {
  direct = 'Direct',
  organic = 'Organic',
  cpc = 'CPC',
  referral = 'Referral',
  email = 'Email',
}

export enum TrafficSourceUrl {
  google = 'google.com',
  bing = 'bing.com',
  yandex = 'yandex.ru',
  trello = 'trello.com',
  chromeStore = 'chrome.google.com',
  planyway = 'planyway.com',
}

export enum TrafficSources {
  direct = 'Direct',
  none = 'None',
  'google.com' = 'Google',
  'bing.com' = 'Bing',
  'yandex.ru' = 'Yandex',
  'trello.com' = 'Trello.com',
  'chrome.google.com' = 'Chrome.google.com',
}

export enum ContactUsSource {
  Enterprise = 'Enterprise',
  NonProfit = 'Non-profit',
  ContactSupport = 'Contact Support',
  ContactSales ='Contact Sales'
}

export const DISABLE_GOOGLE_ANALYTICS_FLAG = 'ga-disable-UA-71094211-12';
