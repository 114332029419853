import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { concatMap, delay, map, retryWhen } from 'rxjs/operators';

import { LogService } from '@app/core/services/log/log.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private _http: HttpClient, private _logSrv: LogService) {
  }

  get<T>(url: string, params: { headers?: any, withCredentials?: boolean, query?: object, observe?: any } = {}, maxRetry = 3): Observable<T> {
    return this._rest<T>('GET', url, params, maxRetry);
  }

  post<T>(url: string, params: { headers?: any, withCredentials?: boolean, body?: any, query?: object, observe?: any } = {}, maxRetry = 3): Observable<T> {
    return this._rest<T>('POST', url, params, maxRetry);
  }

  put<T>(url: string, params: { headers?: any, withCredentials?: boolean, body?: object, observe?: any } = {}, maxRetry = 3): Observable<T> {
    return this._rest<T>('PUT', url, params, maxRetry);
  }

  batch<T>(baseUrl, urls, params: { headers?: any, withCredentials?: boolean, query?: object, observe?: any } = {}, maxRetry = 3): Observable<T[]> {
    const batchQuery = `${baseUrl}/batch/?urls=${encodeURIComponent(urls.join())}`;

    return this.get<IBatchResponseObject<T>[]>(batchQuery, params, maxRetry)
      .pipe(
        map(batchResponse => {
          const responseObjects: T[] = [];
          for (const response of batchResponse) {
            const responseObject = response['200'];
            if (!responseObject) {
              this._logSrv.warn(`Batch request error, message: "${response.message}", code: "${response.statusCode}"`);
              continue;
            }

            responseObjects.push(responseObject);
          }

          return responseObjects;
        })
      );
  }

  /**
   * Выполняет запрос с указанными параметрами
   *
   * @param {string} method метод запроса
   * @param {string} url путь запроса
   * @param {{ headers?: object, withCredentials?: boolean, data?: any }} params опциональные данные запроса
   * @param {{
   *      headers?: any,
   *      data?: any
   * }} params опциональные данные запроса
   * @param {number} maxRetry  количество попыток запроса, по умолчанию - 3 попытки
   *
   * @returns {Promise<T>} результат запроса
   * @private
   */
  private _rest<T>(
    method: string,
    url: string,
    params?: { headers?: object, withCredentials?: boolean, body?: any, query?: any, observe?: any },
    maxRetry: number = 3
  ): Observable<T> {
    const options = {
      headers: {
        'content-type': 'application/json',
        ...params.headers
      },
      withCredentials: params.withCredentials,
      body: params.body,
      params: params.query,
      observe: params.observe,
    };

    return this._http.request<T>(method, url, options).pipe(this._httpRetry());
  }

  private _httpRetry(maxRequests = 3) {
    let retryDelay = 0;
    return (src: Observable<any>) => src.pipe(
      retryWhen(errors => errors
        .pipe(
          concatMap((error, count) => {
            if (count < maxRequests) {
              return throwError(error);
            }

            if (error.status >= 500 || error.status === -1 || error.status === 429) {
              if (error.status === -1) {
                this._logSrv.warn('Request timeout!');
              }
              else if (error.status === 429) {
                this._logSrv.warn('Request limit exceeded...');
              }
              else {
                this._logSrv.warn(`Request error! Response:\n${JSON.stringify(error, null, '    ')}`);
              }

              retryDelay = 1000 + 2000 * count;
              return of(error.status);
            }

            return throwError(error);
          }),
          delay(retryDelay)
        )
      )
    );
  }
}

interface IBatchResponseObject<T> {
  200: T;
  message: string | undefined;
  statusCode: number | undefined;
}
