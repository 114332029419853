import { AccountType } from '@planyway-common/common';
export interface AuthResponseData {
  userId: string;
}

export const TRELLO_AUTH_VERSION = 1;
export const TRELLO_AUTH_ENDPOINT = 'https://trello.com';
export const TRELLO_AUTH_BASE_URL = `${TRELLO_AUTH_ENDPOINT}/${TRELLO_AUTH_VERSION}`;

export const GOOGLE_AUTH_BASE_URL = 'https://accounts.google.com/o/oauth2/v2/auth';

export const PLANYWAY_AUTH_URL = 'planyway/auth';

export const PLANYWAY_TOKEN_KEY = 'planywayPlanywayToken';
export const PLANYWAY_AUTH_TOKEN_KEY = 'planywayAuthToken';

export const AUTH_TOKEN_HEADER_NAME = 'x-pw-auth-token';
export const AUTH_TOKEN_USE_HEADER_NAME = 'x-pw-auth-use-header';
export const SESSION_TOKEN_HEADER_NAME = 'x-pw-session-token';

export enum FailureCode {
  AuthInvalidToken = 'AUTH_INVALID_TOKEN',
  AuthTokenExpired = 'AUTH_TOKEN_EXPIRED',
  AuthUnauthorized = 'AUTH_UNAUTHORIZED',
  AuthUserNotFound = 'AUTH_USER_NOT_FOUND',
}

export enum GoogleScope {
  Calendar = 'https://www.googleapis.com/auth/calendar',
  Contacts = 'https://www.googleapis.com/auth/contacts.readonly',
  Email = 'https://www.googleapis.com/auth/userinfo.email',
  OpenId = 'openid',
  Profile = 'https://www.googleapis.com/auth/userinfo.profile',
}

export enum OutlookScope {
  CalendarsRead = 'Calendars.Read',
  CalendarsReadWrite = 'Calendars.ReadWrite',
  ContactsRead = 'Contacts.Read',
  PeopleRead = 'People.Read',
  UserRead = 'User.Read',
  UserReadBasicAll = 'User.ReadBasic.All',
}
