import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusyService {
  // noinspection JSMismatchedCollectionQueryUpdate
  private _progressComponentsSet: Set<number> = new Set<number>();

  private _isAppBusySubject = new ReplaySubject<boolean>();
  readonly isAppBusy$ = this._isAppBusySubject.asObservable();

  private _isAppBusy = false;

  constructor(
    @Inject(PLATFORM_ID) private _platformId
  ) {
  }

  /**
   * Устанавливает состояние прогрессбара для указанного компонента
   *
   * @param {number} componentId идентификатор компонента
   * @param {boolean} isShown True, если необходимо показать индикатор, иначе - False
   */
  setProgressState(componentId: number, isShown: boolean) {
    if (isShown) {
      this._progressComponentsSet.add(componentId);
    }
    else {
      this._progressComponentsSet.delete(componentId);
    }
  }

  get isBusy() {
    if (isPlatformBrowser(this._platformId)) {
      return this._progressComponentsSet.size > 0;
    }

    return false;
  }

  get isAppBusy() {
    if (isPlatformBrowser(this._platformId)) {
      return this._isAppBusy;
    }

    return false;
  }

  set isAppBusy(value) {
    this._isAppBusySubject.next(value);
    this._isAppBusy = value;
  }
}
