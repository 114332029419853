import { Injectable } from '@angular/core';
import { first } from 'rxjs';
import { AccountService } from '../account/account.service';
import { AuthService } from '../auth/auth.service';
import { BrowserService } from '../browser/browser.service';

@Injectable({
  providedIn: 'root',
})
export class StandaloneService {
  constructor(
    private _accountSrv: AccountService,
    private _authSrv: AuthService,
    private _browserSrv: BrowserService,
  ) {}

  openStandaloneApp() {
    this._authSrv.isAuthenticated && this._authSrv.isTrelloPrimaryAccount ? this._onGoToAppClick() : this._continueWithTrello();
  }

  private _onGoToAppClick() {
    this._browserSrv.openStandalone();
  }

  private _continueWithTrello() {
    if (this._browserSrv.isMobileDevice()) {
      this._browserSrv.openStandalone();
      return;
    }

    this._accountSrv.signInByFragment().pipe(first()).subscribe();
  }
}
