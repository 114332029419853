import { Injectable, Inject } from '@angular/core';
import { WINDOW } from '@app/core/services/window/window.service';
import { ScriptService } from '@app/core/services';
import { ProfitWellRetainEnabled, ProfitWellRetainToken } from '@app/shared/constants';

const PROFITWELLRETAIN_SCRIPT = '(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+\'?auth=\'+s.getElementById(o+\'-js\').getAttribute(\'data-pw-auth\');m.parentNode.insertBefore(a,m);})(window,document,\'profitwell\',\'script\',\'https://public.profitwell.com/js/profitwell.js\');profitwell(\'start\', {});';

@Injectable({
    providedIn: 'root'
  })
export class ProfitWellRetainService {
    private _isScriptLoaded: boolean;

    constructor(
        @Inject(WINDOW) private _window: Window | any,
        private _scriptSrv: ScriptService
      ) {
      }

      init() {
        if (!ProfitWellRetainEnabled) {
          return;
        }

        if(this._isScriptLoaded) {
          return;
        }

        this._scriptSrv.appendRawScript(PROFITWELLRETAIN_SCRIPT, 'profitwell-js', null, [{ key:"data-pw-auth", value: ProfitWellRetainToken}] )
        this._isScriptLoaded = true;
      }
}