<div class="cookie-warning">
  <div class="cookie-warning__wrapper">
    <div class="cookie-warning__text">By continuing to use the website, you consent to the use of cookies. <a
      class="cookie-warning__link"
      routerLink="/legal/privacy-policy"
      (click)="emitClose()"
    >Read More</a></div>
    <button mat-icon-button (click)="emitClose()" aria-label="Close notice">
      <mat-icon aria-label="Close notice">close</mat-icon>
    </button>
  </div>
</div>
