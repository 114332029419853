import { Injectable } from '@angular/core';
import { catchError, filter, finalize, map, shareReplay, switchMap, tap, } from 'rxjs/operators';
import {
  BillingPeriod,
  IPrice,
  IPricing,
  ISale,
  isCurrencyWithoutFraction,
  LicenseType,
  NotificationType,
  PaymentPeriod,
  PaymentPlatform,
} from '@app/shared/models';

import dayjs from 'dayjs';
import { BACKEND_URL } from '@app/shared/constants';
import { Observable, of } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { Platform as ProductPlatform } from '@app/core/services/platform/platform.model';
import { HttpService } from '@app/core/services/http/http.service';
import { LogService } from '@app/core/services/log/log.service';
import { NotificationService } from '@app/core/services/notification/notification.service';
import { PlatformService } from '../platform/platform.service';
import { BrowserService } from '../browser/browser.service';

const SALE_EXPIRATION_DATE = new Date('2023-04-17');

const SALE_CONFIG = {
  [BillingPeriod.Annual]: {
    isActive: false,
    coefficient: 0.8,
  },
  [BillingPeriod.Monthly]: {
    isActive: false,
    coefficient: 0.8,
  }
}

@Injectable({
  providedIn: 'root',
})
export class PricingService {
  pricing: IPricing;

  _isVatIncluded: boolean;
  _paymentPlatform: PaymentPlatform;

  private _sale;

  readonly billingPlatformPeriods = {
    [PaymentPlatform.Paddle]: [
      BillingPeriod.Monthly,
      BillingPeriod.Annual
    ],
    [PaymentPlatform.CloudPayments]: [
      BillingPeriod.Monthly
    ]
  }

  readonly paymentPlatformPeriods = {
    [PaymentPlatform.Paddle]: [
      PaymentPeriod.Year,
      PaymentPeriod.Month
    ],
    [PaymentPlatform.CloudPayments]: [
      PaymentPeriod.Month
    ]
  }

  constructor(
    private _platform: Platform,
    private _platformSrv: PlatformService,
    private _http: HttpService,
    private _notificationSrv: NotificationService,
    private _logSrv: LogService,
    private _browserSrv: BrowserService,
  ) {}

  get isVatIncluded() {
    return this._isVatIncluded;
  }

  get paymentPlatform() {
    return this._paymentPlatform;
  }

  get sale() {
    if (!this._sale) {
      const saleCondition = {
        [BillingPeriod.Annual]: () => dayjs().isBefore(SALE_EXPIRATION_DATE) && this.paymentPlatform === PaymentPlatform.Paddle,
        [BillingPeriod.Monthly]: () => false,
      }

      this._sale = {
        coupon: 'EASTER_2023',
        img: '/assets/img/easter-2023.png',
        imgAlt: 'Easter 2023',
        [BillingPeriod.Annual]: {
          isActive: dayjs().isBefore(SALE_EXPIRATION_DATE) && this.paymentPlatform === PaymentPlatform.Paddle,
          coefficient: 0.8,
        } as ISale,
        [BillingPeriod.Monthly]: {
          isActive: false,
          coefficient: 0.8,
        } as ISale,
      }

      for (let [ period, config ] of Object.entries(SALE_CONFIG)) {
        let saleConfig = config;

        Object.defineProperty(saleConfig, 'isActive', {
          get: saleCondition[period]
        })

        this._sale[period] = saleConfig;
      }
    }

    return this._sale;
  }

  get isSaleActive() {
    return this.sale[BillingPeriod.Annual].isActive || this.sale[BillingPeriod.Monthly].isActive;
  }

  get pricingLink(): string {
    switch (this._platformSrv.currentPlatform) {
      case ProductPlatform.Trello:
      case ProductPlatform.Extension:
        return '/pricing'

      case ProductPlatform.Jira:
        return this._browserSrv.jiraMarketPlacePricingUrl;
    }
  }

  get isPricingLinkExternal() {
    return this._platformSrv.currentPlatform === ProductPlatform.Jira;
  }

  isPaymentPeriodAvailable(platform, period) {
    return this.paymentPlatformPeriods[platform]?.includes(period);
  }

  isBillingPeriodAvailable(period) {
    return this.billingPlatformPeriods[this.paymentPlatform]?.includes(period);
  }

  /**
   * Загружает прайсинг
   */
  loadPricing(): Observable<IPricing> {
    return of(this._platform.isBrowser).pipe(
      filter(isBrowser => isBrowser),
      switchMap(() => {
        return this._http.get<any>(`${BACKEND_URL}/payment/pd/prices`).pipe(
          map(response => {
            const { success, data, message, code, details } = response;
            if (success) {
              return data;
            }

            this._notificationSrv.show(
              NotificationType.Error,
              `Something went wrong: ${message} (${code})`,
            );
            this._logSrv.error('Failed to load pricing: ', message);
            this._logSrv.error(details);
            return {};
          }),
          map(pricing => {
            const licensePricing: IPricing = {};
            for (const licenseType of Object.values(LicenseType)) {
              const licensePrices = pricing[licenseType];
              if (!licensePrices) {
                continue;
              }

              const monthlyPrice = licensePrices['MONTH'];
              const annualPrice = licensePrices['YEAR'];

              this._isVatIncluded = annualPrice?.isVatIncluded;

              if (monthlyPrice?.currency === 'RUB' || annualPrice?.currency === 'RUB') {
                this._paymentPlatform = PaymentPlatform.CloudPayments;
              } else {
                this._paymentPlatform = PaymentPlatform.Paddle;
              }

              licensePricing[licenseType] = {
                [BillingPeriod.Monthly]: {
                  isVatIncluded: monthlyPrice?.isVatIncluded,
                  currency: monthlyPrice?.currency,
                  price: monthlyPrice?.price,
                  tax: 0,
                  period: 1,
                },
                [BillingPeriod.Annual]: {
                  isVatIncluded: annualPrice?.isVatIncluded,
                  currency: annualPrice.currency,
                  price: annualPrice.price / 12,
                  tax: 0,
                  period: 12,
                },
              };
            }

            return licensePricing;
          }),
          catchError(e => {
            this._logSrv.error('Failed to load Planyway pricing: ', e.message);

            this._notificationSrv.show(
              NotificationType.Error,
              `Something went wrong: (${e.status}) ${e.message}`,
            );
            return of(null);
          }),
        );
      }),
      shareReplay(1),
    );
  }

  roundPriceValue({ currency, price, tax }: IPrice, quantity: number) {
    if (isCurrencyWithoutFraction(currency)) {
      return {
        perUser: {
          priceInt: Math.ceil(price),
          priceMod: 0,
        },
        total: {
          priceInt: Math.ceil(price) * quantity,
          priceMod: 0,
        },
      };
    }

    let roundedValue = Math.round(price * 100) / 100;
    const perUser = {
      priceInt: Math.trunc(roundedValue),
      priceMod: Math.round((roundedValue - Math.trunc(roundedValue)) * 100),
    };

    roundedValue = Math.round(price * quantity * 100) / 100;
    const total = {
      priceInt: Math.trunc(roundedValue),
      priceMod: Math.round((roundedValue - Math.trunc(roundedValue)) * 100),
    };

    roundedValue = Math.round(tax * 100) / 100;
    const perUserTax = {
      priceInt: Math.trunc(roundedValue),
      priceMod: Math.round((roundedValue - Math.trunc(roundedValue)) * 100),
    };

    return {
      perUser,
      perUserTax,
      total,
    };
  }
}
